<template>
  <v-app>

    <!-- Cool Transition ~ looks funky with new navs on each page -->
    <!-- <transition name="fade-transition" mode="out-in"> -->

    <!-- Router Outut -->
    <router-view/>

    <!-- </transition> -->

    <bottom-bar></bottom-bar>

  </v-app>
</template>

<script>
  import BottomBar from '@/components/misc/BottomBar.vue'
  export default {
    name: 'app',
    components: {
      BottomBar,
    },
    mounted() {
      let queries = getURLQueries();

      // firstGiftToken check
      if(queries) {
        if(queries.token) {
          this.$store.dispatch('pingFirstGiftToken', queries.token)
          .catch((error) => {
            console.log(error);
          });
        }
      }else if( this.$store.getters.firstGiftToken ) {
        this.$store.dispatch('pingFirstGiftToken', this.$store.getters.firstGiftToken)
        .catch((error) => {
          console.log(error);
        });
      }

      // tango card availability
      this.$store.dispatch('pingTangoCard');

    },
  }

  function getURLQueries() {
    if(window.location.search) {
      return JSON.parse('{"' +
        decodeURI(window.location.search.substring(1))
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g,'":"') +
      '"}');
    }
    return null;
  }
</script>

<!-- Global CSS -->
<style>

  html {
    scroll-behavior: smooth;
  }

  /****************************************************/
  /* utils */

  .text-center {
    text-align: center;
  }
  .text-left {
    text-align: left;
  }

  .pos-rel {
    position: relative;
  }

  .pos-abs {
    position: absolute;
  }

  .w-100 {
    width:100%;
  }

  .h-100 {
    height: 100%;
  }

  .rel-container {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .abs-container {
    position: absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
  }

  .t0 { top: 0; }
  .b0 { bottom: 0; }
  .l0 { left: 0; }
  .r0 { right: 0; }

  .abs-top-left {
    position: absolute;
    top: 0;
    left: 0;
  }

  .text-decoration-none { text-decoration: none; }
  .va-top { vertical-align: top; }
  .va-middle { vertical-align: middle; }

  .my-block { display: block; }
  .my-inline-block { display: inline-block; }

  .my-flex { display: flex; }
  .my-flex-center { align-items: center; justify-content: center; }

  .pa-0 {
    padding: 0;
  }

  .pa-0_25 {
    padding: 1px;
  }

  .pa-0_5 {
    padding: 2px;
  }

  .unselectable {
    -webkit-user-select:none;
    -khtml-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    -o-user-select:none;
    user-select:none;
  }

  .clickable { cursor: pointer; }

  /****************************************************/
  /* general */

  /* prevent scroll bar when its not needed */
  /* actually not that big of a deal... */
  /*
  html {
    overflow-y: auto;
  }
  */

  .logo {
    font-family:Nunito, sans-serif;
  }
  .slogan {
    font-family:Lato, sans-serif;
  }

  /* tabs with no visual tab bar */
  .v-tabs.no-bar > .v-tabs__bar {
    display: none;
  }

  /* tabs that take up container */
  .v-tabs.full-height-tabs {
    height: 100%;
  }
  .v-tabs.full-height-tabs > .v-tabs__items {
    height: 100%;
  }
  .v-tabs.full-height-tabs > .v-tabs__items > .v-tabs__content {
    height: 100%;
  }

  /* allow overflow for floating titles  */
  .v-tabs__items {
    overflow: visible;
  }

  /* prevent all caps */
  * {
    text-transform: none !important;
  }

  /****************************************************/
  /* separator lines */

  .line-side {
    position: absolute;
    top: 6%;
    bottom: 6%;
    right: -1px;
    width: 1.5px;
    background-color: lightgrey;
  }

  .line-bottom {
    position: absolute;
    right: 6%;
    left: 6%;
    bottom: -1px;
    height: 1.5px;
    background-color: lightgrey;
  }

  .border-top {
    border-top-color: lightgrey !important;
    border-top-style: solid;
    border-top-width: 1px;
  }

  .border-top-light {
    border-top-color: WhiteSmoke !important;
    border-top-style: solid;
    border-top-width: 1px;
  }


  /****************************************************/
  /* center-scroll-x */

  .center-scroll-x {
    text-align: center;
    white-space: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling:touch;
  }
  .center-scroll-x::-webkit-scrollbar-track {
    /* border-radius: 8px; */
    /* background-color: #F7F7F7; */
  }

  .center-scroll-x::-webkit-scrollbar {
    height: 8px;
    /* background-color: #F7F7F7; */
  }
  .center-scroll-x::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #DFDFDF;
  }
  .center-scroll-x.dark::-webkit-scrollbar-thumb {
    background-color: #CCCCCC;
  }

  /****************************************************/
  /* blogs */

  .blog-hr {
    margin-top: 24px;
    margin-left: 24px;
    margin-right: 24px;
    height: 2px;
    border: 0;
    background-color: #f0f0f0;
  }

  .theme-font {
    font-family: Nunito, sans-serif;
  }

  .my-page {
    min-height: 100vh;
    background-color: white;
  }
  .my-page-top-padded {
    padding-top: 72px;
  }

  .blog-text-very-dark-gray {
    color: hsla(216, 85%, 25%, 1);
  }
  .blog-text-dark-gray {
    color: hsla(216, 40%, 45%, 1);
  }
  .blog-text-gray {
    color: hsla(216, 30%, 65%, 1);
  }

  .my-text-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 850px;
    padding-right: 12px;
    padding-left: 12px;
  }

  .article {
    font-size: 17px;
  }
  .article * {
    margin-bottom: 12px;
  }
  .article img {
    max-width: 100%;
  }

  .article-title {
    font-weight: 400;
    font-size: 30px;
    margin-top: 36px;
    margin-bottom: 12px;
  }
  @media only screen and (max-width: 620px) {
    .article-title {
      margin-top: 16px;
      font-size: 22px;
    }
  }

  .article-description {
    font-size: inherit;
    font-weight: inherit;
  }

  /****************************************************/
  /* misc */

  .button.huge {
    font-family: Nunito, sans-serif;
    /* font-family: Lato, sans-serif; */
    font-weight: 500;
    /* letter-spacing: 1.2px; */
    font-size: 30px;
    padding: 40px;
    padding-left: 50px;
    padding-right: 50px;
    border-radius: 10px;
  }
  .button.demo-button {
    font-family: Nunito, sans-serif;
    /* font-family: Lato, sans-serif; */
    font-weight: 500;
    font-size: 26px;
    padding: 36px;
    padding-left: 60px;
    padding-right: 60px;
    border-radius: 8px;
    background-color: #00b85c !important;
    /* #00bd5e */
  }

</style>
