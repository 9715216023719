import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';

var CONFIG_PRODUCTION = {
  apiKey: "AIzaSyA-kt4Y09mGXXgR6n_M_GgFjVCDYKEx83w",
  authDomain: "swipewrap-platform.firebaseapp.com",
  databaseURL: "https://swipewrap-platform.firebaseio.com",
  projectId: "swipewrap-platform",
  storageBucket: "swipewrap-platform.appspot.com",
  messagingSenderId: "354013356756"
};

var CONFIG_DEV = {
  apiKey: "AIzaSyDP0-7fNJKPCh2G0gIpyBiq4daI6tCcurU",
  authDomain: "swipewrap-platform-dev.firebaseapp.com",
  databaseURL: "https://swipewrap-platform-dev.firebaseio.com",
  projectId: "swipewrap-platform-dev",
  storageBucket: "swipewrap-platform-dev.appspot.com",
  messagingSenderId: "498017812480"
};

let config;
switch (process.env.NODE_ENV) {
  case 'production':  config = CONFIG_PRODUCTION; break;
  case 'development': config = CONFIG_DEV; break;
}

firebase.initializeApp(config);

export default firebase
