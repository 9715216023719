import debug from '@/util/debug'

import clone from '@/misc/clone'
import fromString from '@/misc/from-string'

import { defaultGift } from '@/static/customizer'

import heap from '@/libs/heap'

function getSessionStoredGiftAndDoHeapPings(item) {
  let gift = JSON.parse(sessionStorage.getItem(item));

  if(!gift) { return null; }

  // gift.contentInfo.file.url
  if(gift.contentInfo) {
    if(gift.contentInfo.file) {
      gift.contentInfo.file.url = heap.ping(gift.contentInfo.file.url, '_', '/gift/content');
    }
  }

  // gift.personal.wrap
  if( gift.personal.usingCustomWrap == true ) {
    gift.personal.wrap.src = heap.ping(gift.personal.wrap.src, '_', '/gift/personal/wrap/src');
  }

  // gift.screenshot
  if( gift.screenshot ) {
    gift.screenshot = heap.ping(gift.screenshot, '_', '/gift/screenshot');
  }

  // gift.screenshotWide
  if( gift.screenshotWide ) {
    gift.screenshotWide = heap.ping(gift.screenshotWide, '_', '/gift/screenshotWide');
  }

  // gift.personal.builder
  let builderPings = JSON.parse(sessionStorage.getItem('builderPings'));
  if( builderPings ) {
    if( gift.personal.builder ) {
      if( gift.personal.builder.items ) {
        for(let i in gift.personal.builder.items) {
          let src = gift.personal.builder.items[i].src;
          let name = builderPings[i];
          gift.personal.builder.items[i].src = heap.ping(src, name + '.png', '/gift/personal/builder/items/');
        }
      }
    }
  }

  // console.log('builderPings');
  // console.log(builderPings);

  // debug
  // console.log('gift');
  // console.log(JSON.stringify(gift, null, 2));

  // console.log('session stored gift raw:');
  // console.log(JSON.stringify(sessionStorage.getItem(item)));

  return gift;
}

// customizer state that needs to persist
const state = {

  // ------------------ //
  // requests sent to customizer

  // desired gift content type ~ 'tango-card', 'link', 'pdf', 'youtube'
  contentTypeIntent:null,

  // desired gift to be stage
  stageIntent:null,

  // ------------------ //
  // content being currently edited

  // on screen data (could be an edit of a cart gift) ~ session stored
  stage:getSessionStoredGiftAndDoHeapPings('customizerStage'),  // JSON.parse(sessionStorage.getItem('customizerStage')),

  // function to call when staged-in data is done with (place back into cart, update existing gift) ~ session stored
  stageCallback:eval('(' + sessionStorage.getItem('customizerStageCallback') + ')'),
  stageCallbackData:JSON.parse(sessionStorage.getItem('stageCallbackData')),

  // copy of stage when stage is unstaged ~ session stored
  stash:JSON.parse(sessionStorage.getItem('customizerStash')),

}

const mutations = {

  setCustomizerStage(state, value) {
    state.stage = value;
    sessionStorage.setItem('customizerStage', JSON.stringify(value));
  },

  setCustomizerStash(state, value) {
    state.stash = value;
    sessionStorage.setItem('customizerStash', JSON.stringify(value));
  },

  setCustomizerStageCallback(state, func) {
    state.stageCallback = func;
    sessionStorage.setItem('customizerStageCallback', func.toString());
    debug.log('stageCallback saved...');
  },

  setCustomizerStageCallbackData(state, data) {
    state.stageCallbackData = data;
    sessionStorage.setItem('stageCallbackData', JSON.stringify(data));
    debug.log('stageCallbackData saved...');
  },

  setCustomizerStageIntent(state, value) {
    state.stageIntent = value;
  },

  setCustomizerContentTypeIntent(state, value) {
    state.contentTypeIntent = value;
  },

  setCustomizerStageProperty(state, {key, value, ignoreSave}) {
    // null check
    if(!state.stage) { console.log('STAGE IS NULL. CANNOT SET KEYS.'); return; }

    // set desired nested object value
    let result = fromString(state.stage, key, value);

    // if key doesn't exist, just return
    if(result == null && value != null) {return;}

    // save to local storage ~ if not ignoring it
    if(ignoreSave) {
      debug.log('stage not saved...');
    }else{
      sessionStorage.setItem('customizerStage', JSON.stringify(state.stage));
      debug.log('stage saved...');
    }

  },

}

const actions = {

  setCustomizerContentTypeIntent({commit}, value) {
    commit('setCustomizerContentTypeIntent', value);
  },

  setCustomizerStageIntent({state, commit}, value) {
    if( state.stageIntent ) { console.log('WARNING: STAGE INTENT OBJECT ALREADY EXSISTS. OVERWRITING.'); }
    commit('setCustomizerStageIntent', value);
  },

  setCustomizerStageCallback({state, commit}, {func, data}) {
    if( state.stageCallback ) { console.log('WARNING: STAGE CALLBACK FUNCTION ALREADY EXSISTS. OVERWRITING.'); }
    if( state.stageCallbackData ) { console.log('WARNING: STAGE CALLBACK DATA ALREADY EXSISTS. OVERWRITING.'); }
    commit('setCustomizerStageCallback', func);
    commit('setCustomizerStageCallbackData', data);
  },

  runCustomizerStageCallback({state, getters, commit, dispatch}) {
    if(state.stageCallback) {
      state.stageCallback({getters, commit, dispatch}, state.stageCallbackData);
      commit('setCustomizerStageCallback', null);
      commit('setCustomizerStageCallbackData', null);
    }else{
      console.log('STAGE CALLBACK FUNCTION DOES NOT EXIST.');
    }
  },

  startCustomizer({state, getters, commit, dispatch}) {

    // Case 1: stageIntent object has priority
    if(state.stageIntent) {
      if(state.stage) {
        dispatch('stashCustomizerStage');
      }
      commit('setCustomizerStage', clone(state.stageIntent));
      commit('setCustomizerStageIntent', null);
      debug.log( 'customizer: Staged stageIntent object' );
      return;
    }

    // Case 2: consider contentTypeIntent
    if(state.contentTypeIntent) {
      let prevContentType;

      // existing stage with mode='new'
      if(state.stage && getters.customizerMode === 'new') {
        prevContentType = getters.customizerStageProperty('contentType');
        commit('setCustomizerStageProperty', {key:'contentType', value:state.contentTypeIntent});

      // or existing stash with mode='new'
      }else if( state.stash ) {
        dispatch('stageCustomizerStash');
        if(state.stage && getters.customizerMode === 'new') {
          prevContentType = getters.customizerStageProperty('contentType');
          commit('setCustomizerStageProperty', {key:'contentType', value:state.contentTypeIntent});
        }
      }

      // if stage still doesn't have mode='new', create a new gift
      if(!(state.stage && getters.customizerMode === 'new')) {
        commit('setCustomizerStage', clone(defaultGift));
        prevContentType = getters.customizerStageProperty('contentType');
        commit('setCustomizerStageProperty', {key:'contentType', value:state.contentTypeIntent});
      }

      // clear content info if contentType was changed
      if(prevContentType && getters.customizerContentType != prevContentType) {
        commit('setCustomizerStageProperty', {key:'contentInfo', value:null});
      }

      // done...
      commit('setCustomizerContentTypeIntent', null);
      debug.log( 'customizer: Considered contentTypeIntent' );
      return;
    }

    // Case 3: no intents & stage, staged should gift already be loaded. no action needed.
    if(state.stage) {
      debug.log( 'customizer: Stage already exists' );
      return;
    }

    // Case 4: no intents & no stage, stage the stash
    if(!state.stage && state.stash) {
      dispatch('stageCustomizerStash');
      debug.log( 'customizer: Stash has been staged' );
      return;
    }

    // Case 5: no intents & no stage & no stash, start new from default gift
    if(!state.stage && !state.stash) {
      commit('setCustomizerStage', clone(defaultGift));
      debug.log( 'customizer: New gift created' );
      return;
    }

  },

  exportCustomizerStage() {
  },

  stageCustomizerStash({state, commit}) {
    if( !state.stash ) { console.log('STASH IS NULL. STAGING NULL.'); }
    commit('setCustomizerStage', clone(state.stash));
    commit('setCustomizerStash', null);
  },

  stashCustomizerStage({state, commit}) {
    if( !state.stageIntent ) { console.log('WARNING: STAGE INTENT IS NULL. NO REASON TO STASH.'); }
    if( state.stash ) { console.log('WARNING: OVERWRITING ALREADY STASHED OBJECT.'); }
    commit('setCustomizerStash', clone(state.stage));
    commit('setCustomizerStage', null);
  },

  clearCustomizerStage({commit}) {
    commit('setCustomizerStage', null);
  },

}

const getters = {

  customizerMode(state) {
    if(state.stage)
      if('personal' in state.stage && 'free' in state.stage)
        return 'new';
      else
        return 'edit';
    else
      return 'edit';
  },

  customizerContentType(state) {
    if(state.stage)
      if(state.stage.contentType)
        return state.stage.contentType;
      else
        return null;
    else
      return defaultGift.contentType;
  },

  customizerCustomizationMode(state) {
    if(state.stage)
      if(state.stage.customizationMode)
        return state.stage.customizationMode;
      else
        return null;
    else
      return null;
  },

  customizerStageProperty: (state) => (key) => {
    // null check
    if(!state.stage) { console.log('STAGE IS NULL. CANNOT GET KEYS.'); return null; }

    // get desired nested object value
    return fromString(state.stage, key);
  },

  customizerStageCallback(state) { return state.stageCallback },

  customizerStage(state) { return state.stage },

  customizerStash(state) { return state.stash },

}

export default {
	state,
	mutations,
	actions,
	getters
}
