import debug from '@/util/debug'
import axios from 'axios'

const state = {
	token:sessionStorage.getItem('firstGiftToken'),
}

const mutations = {
	setFirstGiftToken(state, val) {
		state.token = val;
		sessionStorage.setItem('firstGiftToken', val);
	},
}

const actions = {

	pingFirstGiftToken({commit, dispatch}, token) {

		return axios.post('/api/first-gift-token', {token})
		.then((response) => {
			let available = response.data;
			if(available) {
				debug.log( 'first-gift-token: token valid' );
				dispatch('showMessage', {type:'success', message:'Valid Free Gift Token.'});
				commit('setFirstGiftToken', token);
			}else{
				debug.log( 'first-gift-token: invalid token' );
				dispatch('showMessage', {type:'error', message:'Invalid Free Gift Token. Token cleared.'});
				commit('setFirstGiftToken', null);
			}
			return Promise.resolve(available);
		});

	},

}

const getters = {
	firstGiftToken(state) { return state.token },
}

export default {
	state,
	mutations,
	actions,
	getters
}
