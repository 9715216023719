import debug from '@/util/debug'

import firebase from './firebase'
import * as firebaseui from 'firebaseui'

import store from '@/store/store'
import axios from 'axios'

// FirebaseUI config.
export const config = {

  autoUpgradeAnonymousUsers: true,                          // Auto upgrades anonymous users
  credentialHelper: firebaseui.auth.CredentialHelper.NONE,  // Prevents 'Accout Chooser'
  signInSuccessUrl: null,
  signInOptions: [
    {provider : firebase.auth.EmailAuthProvider.PROVIDER_ID, requireDisplayName : false}
  ],

  callbacks: {
    signInSuccessWithAuthResult: function(authResult) {
      debug.log( 'firebaseui (lib): sign in success' );
      let callbackPlanned = false;

      // already signed in = anonymous upgrade ~ reset store modules
      if(store.getters.user) {
        if(authResult.user.uid === store.getters.user.uid) {
          callbackPlanned = true;
          Promise.resolve()
          .then(() => store.dispatch('reloadAuth'))      // updates auth user
          .then(() => { callback(authResult.user); })
          .then(() => store.dispatch('clearModules')
          .then(() => store.dispatch('pingModules', {})))      // ping causes verification page
          .catch((error) => { console.log('firebaseui (lib) error:'); console.log(error); });
        }
      }

      // callback ~ if not already planned to be called via a promise
      if(!callbackPlanned) {
        callback(authResult.user);
      }

      // auth store module will ping modules otherwise...

      // go to verify page if needed. (already happens in admin ping, but this prevents page flash)
      // forget it. router.currentRoute didnt know where it was for some reason.
      // if( !authResult.user.isAnonymous && !authResult.user.emailVerified ) {
      //   if( router.currentRoute.path !== '/email-verify')
      // }

      // prevent rediecrt
      return false;
    },

    signInFailure: function(error) {

      // not 'firebaseui/anonymous-upgrade-merge-conflict', do nothing.
      if (error.code != 'firebaseui/anonymous-upgrade-merge-conflict') {
        return Promise.resolve();
      }

      //// ANONYMOUS UPGRADE MERGE ////
      //// This code executes when anonymous user tries to login to an existing user account ////

      // The credential the user tried to sign in with.
      let cred = error.credential;

      debug.log( 'firebaseui (lib): performing merge' );
      //// Copy desired anonymous user data to the existing user.

      let userPrev = store.getters.user;
      let userNew;
      let tokenPrev;
      let tokenNew;

      // generate tokens for prev user and new user
      return Promise.resolve()
      .then(() => userPrev.getIdToken(true))                                    // geneate prev token
      .then((token) => { tokenPrev = token; return Promise.resolve(); })        // save prev token
      .then(() => firebase.auth().signInAndRetrieveDataWithCredential(cred))    // login with new user
      .then((uc) => { userNew = uc.user; return userNew.getIdToken(true); })    // generate new token
      .then((token) => { tokenNew = token; return Promise.resolve(); })         // save new token

      // send merge request to server with both tokens
      .then(() => axios.post('api/user/merge', { tokenPrev, tokenNew }))

      // done ~ reset store modules
      .then(() => store.dispatch('reloadAuth'))      // updates auth user
      .then(() => { callback(userNew); })
      .then(() => store.dispatch('clearModules')
      .then(() => store.dispatch('pingModules', {})))
      .catch((error) => {
        console.log('firebaseui (lib) error:');
        console.log(error);
      });

    }
  },

  // ----------------- //

  // tosUrl and privacyPolicyUrl accept either url string or a callback function.
  tosUrl: '/tos',
  privacyPolicyUrl: '/privacy-policy',
};

// external interface callback 'singleton' ~ gets overwritten everytime start is called
let callback = null;

// FirebaseUI Widget.
export const ui = new firebaseui.auth.AuthUI(firebase.auth());

// FirebaseUI start function.
export function start(elementRef, cb) {
  callback = cb;
  ui.start(elementRef, config);
}

export default {
  config,
  start,
  ui
}
