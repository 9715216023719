
// NOTE: blog/ as a base path is already taken... can't use it
// vue-router doesn't allow child routes to be separate from base route if the base route is used
// --> using article/ instead
// ^
// UPDATE: it might actaully be better for SEO if the articles have no base path ~ ie ~ swipewrap.com/how-wrap-digital-gift

// IMPORTANT: Be sure to add blog paths to vue.config for prerendering

export default [

  // {
  //   title: 'Sample Post',
  //   shortDescription: 'This is a sample blog post',
  //   image: '/images/stock/man-sending-gift.jpg',
  //   path: '/example-article',
  //   name: 'exampleArticle',
  //   component: () => import('@/views/blogs/Example.vue'),
  //   meta: {
  //     title: 'Example Article',
  //     meta: [
  //       { name: 'description', content: 'This is an example article' },
  //     ],
  //   },
  // },

  {
    title: 'Best Way to Gift Wrap an Online Order Delivered Directly to Your Recipient',
    shortDescription: 'Gift wrapping your online orders is now easier than ever with Swipewrap! Send a swipewrap, then let your gifted items arrive later via traditional mail!',
    image: '/images/stock/girl-getting-package-with-swipewrap.jpg',
    path: '/gift-wrap-online-order-delivered-to-recipient',
    name: 'giftWrapOnlineOrderBeingDelivered',
    component: () => import('@/views/blogs/GiftWrapOnlineOrderBeingDelivered.vue'),
    meta: {
      title: 'Best Way to Gift Wrap an Online Order Delivered Directly to Your Recipient',
      meta: [
        { name: 'description', content: 'Gift wrapping your online orders is now easier than ever with Swipewrap! Send a swipewrap, then let your gifted items arrive later via traditional mail!' },
      ],
    },
  },

  // {
  //   title: 'Sample Post 2',
  //   shortDescription: 'This is a sample blog post 2 you should definitely check this thing out',
  //   image: '/images/stock/man-sending-gift.jpg',
  //   path: '/article/example2',
  //   name: 'blogSample2',
  //   component: () => import('@/views/blogs/Example.vue'),
  // },

  // {
  //   title: 'Sample Post 3',
  //   shortDescription: 'This is a sample blog post 3',
  //   image: '/images/stock/man-sending-gift.jpg',
  //   path: '/article/example3',
  //   name: 'blogSample3',
  //   component: () => import('@/views/blogs/Example.vue'),
  // },

  // {
  //   title: 'Sample Post 4 Yes Queen This is cool',
  //   shortDescription: 'This is a sample blog post 2 you should definitely check this thing out',
  //   image: '/images/stock/man-sending-gift.jpg',
  //   path: '/article/example4',
  //   name: 'blogSample2',
  //   component: () => import('@/views/blogs/Example.vue'),
  // },

];
