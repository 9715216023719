<template>

  <!-- Drawer Nav  -->
  <v-navigation-drawer
    v-model="active"
    disable-resize-watcher
    :fixed="fixed"
    :width="200"
    :mobile-break-point="999999999"
    hide-overlay
  >
    <v-list>

      <v-list-tile value="true" v-for="(item, i) in _items" :key="i" :to="item.route" :href="item.link">
        <v-list-tile-content> <v-list-tile-title class="drawer-item theme-font" v-text="item.title"></v-list-tile-title> </v-list-tile-content>
      </v-list-tile>

      <!-- TOM: It looks like this button is a special case since it invokes an action instead of just going to a page -->
      <v-list-tile value="true" v-if="loggedIn" @click="logout" >
        <v-list-tile-content> <v-list-tile-title class="drawer-item theme-font">Log out</v-list-tile-title> </v-list-tile-content>
      </v-list-tile>

    </v-list>
  </v-navigation-drawer>

  <!--
  PROPS:
  // didnt do anything that I could notice ~ maybe needs to be on mobile
  persistent

  // removed due to the way I implemented the navs ~ the navs are not getting pushed and the content is. just awkward.
  app
  -->

</template>

<script>
  import mainMenu from '@/static/main-menu'

  export default {
    name:'nav-drawer',
    props: {
      items: {type:Array, default: null},
      value: Boolean,                      // external v-model binding
      fixed: {type:Boolean, default:true},
    },
    data() {return {active:this.value}},  // internal v-model binding
    computed: {
      _items() {

        // items are passed in...
        if( this.items ) {
          // use them
          return this.items;
        }

        // default
        else{
          // logged in
          if( this.loggedIn ) {
            // OLD: concat method
            // return mainMenu.itemsAll.concat(mainMenu.itemsUser);
            // NEW: full config
            return mainMenu.itemsUser;
          }
          // not logged in
          else{ return mainMenu.itemsAll; }
        }

      },

      loggedIn() {
        return this.$store.getters.user && this.$store.getters.user.emailVerified;
      },

    },
    watch: {
      value(val)  {this.active = val},        // external -> internal
      active(val) {this.$emit('input', val)}, // internal -> external
    },
    methods: {

      logout() {
        this.$store.dispatch('clearAuth');
      },

    }
  }
</script>

<style scoped>
  .drawer-item {
    font-size: 18px;
  }
</style>
