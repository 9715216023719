import Vue from 'vue'
import VueStripeCheckout from 'vue-stripe-checkout'

// base/global options
// these options can be overridden
// by the options in the .open(options)
// function.
const options = {
  key: process.env.VUE_APP_STRIPE_API_KEY_PUBLIC,
  image: 'https://f6s-public.s3.amazonaws.com/profiles/1839600_original.jpg',  // TODO: put a self-hosted image here
  locale: 'auto',
  currency: 'USD',
  billingAddress: false,
  panelLabel: 'Checkout {{amount}}'
}

Vue.use(VueStripeCheckout, options);
