<template>
  <div class="home">

    <!-- main nav (passElement set in mounted) -->
    <main-nav :items="mainNavItems" h1 dark transparent absolute :passElement="$refs.pass"></main-nav>

    <!-- page content -->
    <v-content style="padding-top:0;">

      <!-- Header -->
      <div class="header text-xs-center white--text">

        <div class="header-content rel-container">

          <!-- TOM OLD -->
          <div v-if="false" class="header-slides-container">

            <div class="header-slides" @click="incHeaderTour">

              <div class="header-slide">
                <img src="/images/logo.svg" alt="Swipewrap">
                <h1 class="logo">Swipewrap</h1>
                <h2 class="slogan">Gift Wrap for Digital Gifting</h2>
              </div>

              <!-- TOM I think I was trying to do an animated intro at some point ~ right idea ~~~ don't bother for now though -->
              <!--
              <transition name="fade">
                <div v-if="headerSlide == 0" class="header-slide">
                  <img src="/images/logo.svg" alt="Swipewrap">
                  <h1 class="logo">Swipewrap</h1>
                  <h2 class="slogan">Gift Wrap for Digital Gifting</h2>
                </div>
              </transition>

              <transition name="fade">
                <div v-if="headerSlide == 1" class="header-slide">
                  <h1 class="logo">Swipewrap</h1>
                  <h2 class="slogan">Gift Wrap for Digital Gifting</h2>
                </div>
              </transition>

              <transition name="fade">
                <div v-if="headerSlide == 2" class="header-slide">
                  <v-layout fill-height align-center justify-center>
                    <h2>HELLO</h2>
                  </v-layout>
                </div>
              </transition>
              -->

            </div>

          </div>

          <div class="">

            <!-- inline-block -->

            <div class="header-title theme-font">
              <!-- Digital gift boxes to wrap your digital and physical gifts -->
              <!-- <h2>Personalized digital gift boxes to wrap and deliver your digital gifts</h2> -->
              <b>Discover a new era of gifting</b>
            </div>
            <div class="header-subtitle theme-font">

              <!-- Swipewrap enables you to give the same <b>excitement</b> of traditional,
              in-person gifting with the <b>convenience</b> of the digital world -->

              <!-- Give the same <b>exciting experience</b> of traditional,
              in-person gifts with the <b>convenience</b> of the digital world using Swipewrap -->

              <h2>Send your gifts <b>digitally</b> in a way that is <b>fun, personal, and convenient</b> while your gifted items <b>arrive separately</b></h2>

            </div>

            <div v-if="false" class="">
              <!-- <img src="/images/logo.svg" alt="Swipewrap" style="height:100px;"> -->
              <!-- <h1 class="logo">Swipewrap</h1> -->
              <!-- <h2 class="slogan">Gift Wrap for Digital Gifting</h2> -->
            </div>

          </div>

          <div class="pt-2 pb-3">
            <v-btn class="button huge" color="info" to="/gift-content-type">Send a Gift!</v-btn>
          </div>

          <div class="demo-button-section-content pb-3" style="" v-if="true">
            <!-- <div class="pb-3" style="line-height:5px;">Or try our demo first!</div> -->
            <v-btn class="button demo-button" color="info" to="/demo">Try Demo!</v-btn>
          </div>

          <div class="" style="">
            <a href="#how-it-works" @click="scrollTo('#how-it-works')" class="how-it-works-link theme-font" style="color:white;">How it works</a>
          </div>

          <!-- stock images -->
          <div style="padding-top:36px;">
            <!-- <img class="header-stock-img" src="/images/stock/woman-editing-gift.jpg" alt="Editing swipewrap"> -->
            <img class="header-stock-img" src="/images/stock/woman-opening-gift2.jpg" alt="Editing swipewrap">
            <img class="header-stock-img" src="/images/stock/package-delivered-lighten.jpg" alt="Package delivered">
          </div>

          <div class="theme-font use-cases">
            Holidays &nbsp;&nbsp;|&nbsp;&nbsp; Celebrations &nbsp;&nbsp;|&nbsp;&nbsp; Remote gifting
          </div>

          <div class="theme-font no-download-required" style="">
            <div class="">
              • No account required •
            </div>
            <div class="">
              • No app install required •
            </div>
            <div class="">
              • Works on all devices with web browser •
            </div>
          </div>

        </div>

      </div>

      <!-- Doesn't work consistently... Don't bother with this -->
      <!-- <div ref="pass"></div> -->

      <!-- Testimonial -->
      <div class="testimonial theme-font text-center" style="color:white;">
        <div class="my-inline-block text-left">

          <span class="my-inline-block quote">
            Everyone from kids to adults has absolutely loved it
          </span>
          <br />

          <span class="my-inline-block quote-name">
            Pam
          </span>
          <br />

          <span class="my-inline-block quote-subname">
            Swipewrap user
          </span>

        </div>
      </div>

      <div class="testimonial-transition"></div>

      <!-- TOM OLD -->
      <!-- OLD Demo button section -->
      <div v-if="false" class="demo-button-section text-xs-center white--text" style="margin-top:-2px;">

        <div class="demo-button-section-content pb-3" style="margin-top:-2px;">
          <div class="pb-3" style="line-height:5px;">Or try our demo first!</div>
          <v-btn class="button demo-button" color="info" to="/demo">Try Demo!</v-btn>
        </div>

      </div>

      <!-- Tutorial section -->
      <div id="how-it-works" class="bg-very-light-gray text-very-dark-gray theme-font" style="padding-top:42px; padding-bottom:48px; padding-left: 12px; padding-right: 12px; text-align:center;">

        <!-- <div class="ecommerce-title" style="font-size:14px; font-weight:600; color:hsla(216, 40%, 55%, 1);">E-COMMERCE INTEGRATION</div> -->
        <!-- <div class="ecommerce-title" style="font-size:36px;">E-commerce Integration</div> -->
        <!-- <div class="ma mt-1" style="font-size:24px; max-width:280px;">Want Swipewrap on your E-commerce platform?</div> -->

        <!-- color:hsla(216, 85%, 25%, 1); -->

        <div class="text-center theme-font" style=" margin-bottom:24px;">
          <div class="" style="font-size:28px;">
            How it works
          </div>
          <div class="tutorial-click-steps-note text-dark-gray" style="font-size:18px;">
            Click steps on the left
          </div>
        </div>

        <!-- Tutorial -->
        <div class="tutorial">

          <!-- Tutorial buttons -->
          <div class="tutorial-button-container my-inline-block va-top tutorial-card-height">
            <v-btn class="tutorial-button tutorial-button-top my-block" color="info" style="height:25%; margin:0;" :input-value="(tutorialStep == 1)" @click="tutorialStep = 1">
              <div class="tutorial-button-content-container">
                <b>1</b> - Gift
              </div>
            </v-btn>
            <v-btn class="tutorial-button my-block" color="info" style="height:25%; margin:0;" :input-value="(tutorialStep == 2)" @click="tutorialStep = 2">
              <div class="tutorial-button-content-container">
                <b>2</b> - Upload
              </div>
            </v-btn>
            <v-btn class="tutorial-button my-block" color="info" style="height:25%; margin:0;" :input-value="(tutorialStep == 3)" @click="tutorialStep = 3">
              <div class="tutorial-button-content-container">
                <b>3</b> - Customize
              </div>
            </v-btn>
            <v-btn class="tutorial-button tutorial-button-bottom my-block" color="info" style="height:25%; margin:0;" :input-value="(tutorialStep == 4)" @click="tutorialStep = 4">
              <div class="tutorial-button-content-container">
                <b>4</b> - Delivery
              </div>
            </v-btn>
          </div>

          <!-- Tutorial cards -->
          <div class="tutorial-cards-container my-inline-block">

            <!-- Card -->
            <div class="my-card tutorial-card pt-3" v-show="(tutorialStep == 1 || windowWidth <= 700)">

              <!-- Title and image -->
              <div class="tutorial-card-title text-gray">
                <b>Step 1 - Gift</b>
              </div>
              <div class="tutorial-card-point">
                Purchase a gift from anywhere
              </div>
              <div class="mt-2">
                <img class="" src="/images/stock/just-purchased.jpg" alt="Purchase" />
              </div>

              <!-- Body -->
              <div class="text-left mt-1">

                <div class="tutorial-card-body mt-3">
                  You can send a swipewrap for anything!
                </div>

                <div class="mt-3">
                  <b>Need a gift?</b>
                  <div class="text-dark-gray">
                    Don’t worry! We’ve got you covered with Tango Card!
                  </div>
                </div>

                <div class="mt-3">
                  <b>Just want to show that you care without the “gift”?</b>
                  <div class="text-dark-gray">
                    Send a “swipewrap only” for just the swipewrap. 🙂
                  </div>
                </div>

              </div>

            </div>

            <!-- Card -->
            <div class="my-card tutorial-card pt-3" v-show="(tutorialStep == 2 || windowWidth <= 700)">

              <!-- Title and image -->
              <div class="tutorial-card-title text-gray">
                <b>Step 2 - Upload</b>
              </div>
              <div class="tutorial-card-point-smaller">
                Upload an image, pdf, or link that represents your gift
              </div>
              <div class="mt-2">
                <img class="" src="/images/stock/content-selection2.jpg" alt="Content selection" />
              </div>

              <!-- Body -->
              <div class="text-left mt-1">

                <div class="tutorial-card-body mt-3">
                  Take a screenshot, take a picture, or simply upload a link that shows the item!
                </div>

                <div class="mt-3">
                  <b>Have a digital gift?</b>
                  <div class="text-dark-gray">
                    Upload a link to it!
                  </div>
                </div>

              </div>

            </div>

            <!-- Card -->
            <div class="my-card tutorial-card pt-3" v-show="(tutorialStep == 3 || windowWidth <= 700)">

              <!-- Title and image -->
              <div class="tutorial-card-title text-gray">
                <b>Step 3 - Customize</b>
              </div>
              <div class="tutorial-card-point-smaller">
                Make your gift special by adding your personal touch
              </div>
              <div class="mt-2">
                <img src="/images/stock/woman-editing-gift.jpg" alt="Editing swipewrap">
              </div>

              <!-- Body -->
              <div class="text-left mt-1">

                <div class="tutorial-card-body mt-2 text-center">
                  Your gift recipient will love it!
                </div>

                <div class="text-dark-gray mt-3">
                  • Select custom colors and textures
                </div>

                <div class="text-dark-gray mt-2">
                  • Create your own wrapping paper
                </div>

                <div class="text-dark-gray mt-2">
                  • Select custom fonts
                </div>

                <div class="text-dark-gray mt-2">
                  • Write a personal card
                </div>

              </div>

            </div>

            <!-- Card -->
            <div class="my-card tutorial-card pt-3" v-show="(tutorialStep == 4 || windowWidth <= 700)">

              <!-- Title and image -->
              <div class="tutorial-card-title text-gray">
                <b>Step 4 - Delivery</b>
              </div>
              <div class="tutorial-card-point-smaller">
                Your swipewrap is emailed to your recipient where they can open it
              </div>
              <div class="mt-2">
                <img class="" src="/images/stock/child-opens-christmas-gift-card.jpg" alt="Opening email" />
              </div>

              <!-- Body -->
              <div class="text-left mt-1">

                <div class="tutorial-card-body mt-2">
                  Your uploaded content is revealed when your recipient is done unwrapping.
                </div>

                <div class="tutorial-card-body mt-2">
                  The gifted items can then arrive later!
                </div>

                <div class="text-dark-gray mt-2">
                  And, that's it!
                </div>

                <div class="text-dark-gray mt-2">
                  You've spread some joy! Go you!
                </div>

              </div>

            </div>

          </div>

        </div>

        <div class="demo-button-section-content" style="margin-top:24px;">
          <!-- <div class="pb-3" style="line-height:5px;">Or try our demo first!</div> -->
          <v-btn class="button demo-button" color="info" to="/demo">Try Demo!</v-btn>
        </div>

      </div>

      <!-- Tango Card section -->
      <!-- style="padding-bottom:32px;" -->
      <!-- style="background-color:White;" -->
      <div class="tango-card-section pos-rel text-xs-center py-4">

        <div class="pink--text text--darken-3 theme-font" style="font-size:28px;">Don't have a gift yet?</div>
        <div class="pt-1 pb-4">No problem! Send a Tango Card Gift Card!</div>

        <img class="tangocard-img mr-1" src="/images/tangocard.png" />
        <img class="tangocard-img-2" src="/images/reward-link-cards.png" /><br />

        <div class="tangocard-caption" style="margin:auto;">
          <p>
            A Tango Card is a gift card that lets your recepient choose which store they wish to get credit. This is done through a Tango Card <span class="blue--text">Reward Link</span>.
          </p>
        </div>

        <v-btn dark color="orange darken-2" to="/gift-content-type">Send a Tango Card</v-btn><br />

        <v-btn dark color="grey" @click="dialog=true">How does it work?</v-btn>

        <div class="pb-4"></div>

        <div class="pos-abs text-xs-center grey--text text--lighten-1" style="bottom:0; left:0; right:0;">
          Powered by <a href="https://www.tangocard.com/" target="_blank">Tango Card</a>
        </div>
      </div>

      <!-- Pricing section -->
      <div class="pricing-section py-4">

        <!-- #0d47a1 -->
        <!-- blue--text text--darken-4 -->
        <div class=" text-xs-center theme-font" style="font-size:28px; color:hsla(216, 85%, 25%, 1);">
          Pricing
        </div>

        <v-container>
          <v-layout row wrap justify-center>

            <v-flex xs12 sm4>
              <v-card class="px-2 pt-2 pb-4" style="border-radius:8px;">
                <div class="text-xs-center blue--text text--darken-1 headline">
                  Personal
                </div>
                <div class="text-xs-center blue--text text--darken-2 display-3">
                  99&#162;
                </div>
                <div class="text-xs-center grey--text text--darken-1 subheading">
                  Personalize to fit your recipient
                </div>
                <div class="pos-rel pt-3">
                  <ul style="display:table; margin:0 auto;">
                    <li class="subheading">&#8226; Create your own wrapping paper</li><br />
                    <li class="subheading">&#8226; Select custom colors and textures</li><br />
                    <li class="subheading">&#8226; Select custom card fonts</li><br />
                    <li class="subheading">&#8226; Write a personal card</li><br />
                  </ul>
                </div>
              </v-card>
            </v-flex>

            <v-flex xs12 sm1>
              <v-layout fill-height justify-center align-center class="white--text headline">
                <div class="py-2"></div>
              </v-layout>
            </v-flex>

            <v-flex xs12 sm4>
              <v-card class="px-2 pt-2 pb-4" style="height:100%; border-radius:8px;">
                <div class="text-xs-center green--text text--darken-1 headline">
                  Free
                </div>
                <div class="text-xs-center green--text display-3">
                  Free
                </div>
                <div class="text-xs-center grey--text text--darken-1 subheading">
                  Try out digital gift wrap for free
                </div>
                <div class="pos-rel pt-3">
                  <ul style="display:table; margin:0 auto;">
                    <li class="subheading">&#8226; Write a personal card</li><br />
                    <li class="subheading">&#8226; Great for trying out Swipewrap!</li><br />
                  </ul>
                </div>
              </v-card>
            </v-flex>

          </v-layout>
        </v-container>

        <div class="pt-2 pb-4 text-xs-center">
          <!-- <v-btn large color="info" to="/gift-content-type">Send a Gift!</v-btn> -->
          <v-btn class="button huge" color="info" to="/gift-content-type">Send a Gift!</v-btn>
        </div>

      </div>

      <!-- Facebook like section -->
      <div class="like-section text-xs-center" style="padding-top:128px; padding-bottom:128px;">
        <span class="pink--text text--darken-3 theme-font enjoy-swipewrap-title">Enjoy Swipewrap?</span><br />
        <span class="headline">
          Like us on facebook!
          <v-icon class="mr-1" style="vertical-align:middle;">thumb_up</v-icon>
        </span>
        <br />
        <br />
        <span class="headline">
          <a href="https://facebook.com/swipewrap" target="_blank">facebook.com/swipewrap</a>
        </span>
      </div>

    </v-content>

    <!-- Ecommerce section -->
    <div class="bg-very-light-gray text-very-dark-gray theme-font" style="padding-top:36px; padding-bottom:24px; padding-left: 24px; padding-right: 24px; text-align:center;" v-if="false">

      <!-- <div class="ecommerce-title" style="font-size:14px; font-weight:600; color:hsla(216, 40%, 55%, 1);">E-COMMERCE INTEGRATION</div> -->
      <div class="ecommerce-title" style="font-size:24px;">E-commerce Integration</div>
      <div class="ma mt-1" style="font-size:18px; max-width:280px;">Want Swipewrap on your E-commerce platform?</div>

      <div class="pt-3 pb-3 text-xs-center">
        <a href="https://apps.shopify.com/swipewrap" target="_blank" style="" class="text-decoration-none">
          <v-btn class="button demo-button shopify-app-button" color="info">Open Shopify App</v-btn>
        </a>
      </div>

    </div>

    <!-- Footer  -->
    <!-- <v-footer :fixed="fixed" app> -->
    <!-- <v-footer :fixed="false" color="#B03060" dark> -->
    <div class="footer" style="background-color:#B03060; color: white; padding-top:8px;">

      <!-- rel-container text-xs-center pt-1 -->
      <div class="" style="text-align:center; padding-bottom:8px;">
        <span class="body-2">Copyright &copy; Swipewrap</span>
      </div>

      <!-- text-xs-center pa-3 grey lighten-4 grey--text -->
      <div class="" style="text-align:center; color:#db7b9e; padding-left:18px; padding-right:18px; padding-bottom:8px;">
        In no event will we be liable for any loss or damage including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the use of this website.
        Do not upload private or sensitive information to this website.
        Through this website you are able to link to other websites which are not under the control of Swipewrap.
        We have no control over the nature, content and availability of those sites.
        The inclusion of any links and related content such as files does not necessarily imply a recommendation or endorse the views expressed within them.
        Every effort is made to keep the website up and running smoothly.
        However, Swipewrap and affiliates thereof take no responsibility for, and will not be liable for, the website being temporarily unavailable due to technical issues.
      </div>

      <div class="text-center">
        Need help?
        Email us at <a style="color:#dddddd;" href="mailto:support@swipewrap.com">support@swipewrap.com</a>
      </div>

    </div>
    <!-- </v-footer> -->

    <!-- *************************************************** -->

    <!-- Dialogs  -->

    <v-dialog v-model="dialog">

      <v-card>

        <div class="pa-2 text-xs-center">

          <span class="headline how-it-works-title">Tango Card Reward Link</span><br />
          <span class="subheading grey--text">How it Works</span>

        </div>

        <v-tabs
          v-model="active"
          color="cyan"
          dark
          slider-color="yellow"
          style="overflow-x:hidden;"
          grow
          @click="active++"
        >
          <v-tab :key="0">Select Store</v-tab>
          <v-tab :key="1">Set Amount</v-tab>
          <v-tab :key="2">Get Card</v-tab>

          <v-tab-item :key="0" class="text-xs-center" @click="active++">

            <img src="/images/reward-link-cards.png" class="how-it-works-image" /><br />
            <div class="how-it-works-caption">
              First, your recepient selects where to get credits from a large selection of popular stores including Amazon, Target, and much more!
            </div>

          </v-tab-item>

          <v-tab-item :key="1" class="text-xs-center" @click="active++">

            <img src="/images/reward-link-selection.png" class="how-it-works-image" /><br />
            <div class="how-it-works-caption">
              Once your recepient makes a selection, they specify the amount they would like towards that store.
            </div>

          </v-tab-item>

          <v-tab-item :key="2" class="text-xs-center" @click="active=0">

            <img src="/images/reward-link-checkout.png" class="how-it-works-image" /><br />
            <div class="how-it-works-caption">
              Your recepient then checks out and receives their selected gift card in their email inbox. And, that's it!
            </div>

          </v-tab-item>

        </v-tabs>

        <div class="text-xs-center caption grey--text">Click above to go to next step</div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            flat
            @click="dialog = false"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>

  import mainMenu from '@/static/main-menu'

  import MainNav from '@/components/navs/MainNav.vue'
  import TestCard from '@/components/tmp/TestCard.vue'
  import FirebaseUI from '@/components/auth/FirebaseUI.vue'

  export default {
    name: 'home',
    components: {
      MainNav,
      FirebaseUI,
      TestCard
    },
    data () {
      return {

        tutorialStep: 1,

        headerSlide:0,
        headerSlides:3,
        headerTour:true,
        headerTimeout:null,

        dialog:false,
        active:0,

        windowWidth:0,
        windowHeight:0,

      }
    },
    computed: {
      mainNavItems() {
        return mainMenu.itemsHome;
      },

      howItWorksSlides() {
        return [
          {src:'/images/reward-link-cards.png'},
          {src:'/images/reward-link-selection.png'},
          {src:'/images/reward-link-checkout.png'},
        ];
      }

    },
    mounted() {
      this.animateSlides();
      this.onWindowResize();
      window.addEventListener('resize', this.onWindowResize);
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.onWindowResize);
    },
    methods: {

      scrollTo(selector) {
        document.querySelector(selector).scrollIntoView({ behavior: 'smooth' });
      },

      onWindowResize() {
        this.windowWidth = window.innerWidth || document.documentElement.clientWidth;
        this.windowHeight = document.documentElement.clientHeight;
      },

      clearSessionStorage() {
        sessionStorage.clear();
        console.log( 'Cleared' );
      },

      animateSlides() {
        this.headerTimeout = setTimeout(() => {
            this.headerSlide = (this.headerSlide + 1) % this.headerSlides;
            this.animateSlides();
        }, (this.headerTour) ? 3800 : 8000);
      },

      incHeaderTour() {
        this.headerSlide = (this.headerSlide + 1) % this.headerSlides;
        this.headerTour = false;
        clearTimeout(this.headerTimeout);
        this.animateSlides();
      },

    }
  }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .testimonial {
    padding-left: 52px;
    padding-right: 52px;
    padding-top: 64px;
    padding-bottom: 48px;
    background: linear-gradient(to bottom, #CD4679, #9C2B55);
    background-color: #CD4679;
    /* background: linear-gradient(to bottom, #CD4679, #cf4f80 ); */
    /* background: linear-gradient(to bottom, #ac2f5d, #CD4679, hsla(225, 30%, 95%, 1) ); */
    /* #ac2f5d */
  }
  @media only screen and (max-width: 500px) {
    .testimonial {
      padding-left: 12px;
      padding-right: 12px;
    }
  }

  .quote {
    font-size: 27px;
  }
  .quote:before {
    color: #eee;
    content: open-quote;
    font-size: 4em;
    line-height: 0.1em;
    /* margin-right: 0.15em; */
    vertical-align: -0.4em;
  }
  .quote-name {
    font-size: 26px;
    margin-left: 30px;
    margin-top: 28px;
  }
  .quote-subname {
    font-size: 18px;
    line-height: 12px;
    margin-left: 30px;
  }

  .testimonial-transition {
    background: linear-gradient(to bottom, #cf4f80, hsla(225, 30%, 95%, 1) );
    /* padding-bottom: 30px; */
  }

  .no-download-required {
    font-size: 20px;
    margin-top: 42px;
  }
  @media only screen and (max-width: 500px) {
    .no-download-required {
      font-size: 16px;
      margin-top: 24px;
    }
  }
  @media only screen and (max-width: 350px) {
    .no-download-required {
      font-size: 14px;
      margin-top: 16px;
    }
  }

  .my-card {
    background-color: white;
    -webkit-box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  }

  .tutorial-button-container {
    /* margin-left: 8px; */
  }
  .tutorial-button {
    background-color: white;
    width: 180px;
    font-size: 23px;
  }
  .tutorial-button-content-container {
    text-align: left;
    margin-left: -80px;
    width: 100px;
  }
  .tutorial-button-top {
    border-top-left-radius: 10px;
  }
  .tutorial-button-bottom {
    border-bottom-left-radius: 10px;
  }
  .tutorial-card-height {
    height: 500px;
  }
  .tutorial-card {
    height: 500px;
    width: 450px;
    padding: 12px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .tutorial-card-title {
    font-size: 16px;
  }
  .tutorial-card-point {
    font-size: 24px;
  }
  .tutorial-card-point-smaller {
    font-size: 22px;
  }
  .tutorial-card img {
    max-height: 200px;
    border-radius: 8px;
    box-shadow: 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  }
  .tutorial-card-body {
    font-size: 16px;
  }

  @media only screen and (max-width: 700px) {
    .tutorial-button-container {
      display: none;
    }
    .tutorial-card {
      width: auto;
      max-width: 450px;
      border-radius: 10px;
      margin-bottom: 24px;
    }
    .tutorial-card-point {
      font-size: 18px;
    }
    .tutorial-card-point-smaller {
      font-size: 18px;
    }
    .tutorial-card img {
      max-width: 90%;
    }
    .tutorial-click-steps-note {
      display: none;
    }
  }

  .how-it-works-link {
    font-size: 22px;
  }

  .shopify-app-button {
    font-size: 20px;
    padding: 24px 36px;
  }
  @media only screen and (max-width: 450px) {
    .shopify-app-button {
      font-size: 19px;
      padding: 22px 32px;
    }
  }

  .use-cases {
    font-size: 22px;
  }
  @media only screen and (max-width: 700px) {
    .use-cases {
      font-size: 20px;
    }
  }
  @media only screen and (max-width: 450px) {
    .use-cases {
      font-size: 14px;
    }
  }

  .enjoy-swipewrap-title {
    font-size: 48px;
  }
  @media only screen and (max-width: 700px) {
    .enjoy-swipewrap-title {
      font-size: 32px;
    }
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity 1s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }

  h1, h2 {
    font-weight: 400;
    font-size: inherit;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }

  .theme-font {
    font-family: Nunito, sans-serif;
  }
  .ma {
    margin: auto;
  }
  .block {
    display: block;
  }
  .inline-block {
    display: inline-block;
  }

  .header-title {
    margin: auto;
    margin-top: 64px;
    max-width: 760px;
    /* font-size: 46px; */
    font-size: 52px;
    font-weight: 600;
    padding-left: 24px;
    padding-right: 24px;
  }
  .header-subtitle {
    margin: auto;
    margin-top: 24px;
    max-width: 724px;
    font-size: 22px;
    padding-left: 24px;
    padding-right: 24px;
    margin-bottom: 24px;
  }
  @media only screen and (max-width: 780px) {
    .header-title {
      font-size: 38px;
    }
    .header-subtitle {
      font-size: 19px;
    }
  }

  .header-stock-img {
    margin: 8px;
    border-radius: 8px;
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
    width: 43%;
    max-width: 500px;
  }
  @media only screen and (max-width: 700px) {
    .header-stock-img {
      width: 80%;
    }
  }


  .header {
    background: linear-gradient(to bottom, #9C2B55, #CD4679 );
    /* background: linear-gradient(to bottom, #9C2B55, #9C2B55, #9C2B55, #9C2B55, #9C2B55, #9C2B55, #9C2B55, #9C2B55, #fafafa ); */
    /* #CD4679 #88254A #B03060 #B03060 #D25A87 */
    /* background-image: repeating-linear-gradient(45deg, transparent, transparent 35px, rgba(255,255,255,.5) 35px, rgba(255,255,255,.5) 70px); */
  }
  .header-content {
    min-height: 100vh;
    padding-top: 70px;
    padding-bottom: 8px;
    background-image: repeating-linear-gradient(70deg, transparent, transparent 35px, rgba(255,255,255,.025) 35px, rgba(255,255,255,.025) 70px);
  }
  .header-slides-container {
    height:300px;
  }
  .header-slides {
    position: relative;
    height: 100%;
  }
  .header-slide {
    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .demo-button-section {
    background: linear-gradient(to bottom, #CD4679, #B03060 );
    /* background-color:#88254A; */
    /* background-color:#B03060; */
  }
  .demo-button-section-content {
    margin-top: 2px;
    /* background-image: repeating-linear-gradient(45deg, transparent, transparent 35px, rgba(255,255,255,.03) 35px, rgba(255,255,255,.03) 70px); */
  }


  .pricing-section {
    /* background: linear-gradient(to bottom, #B03060, #CD4679 ); */
    /* background: linear-gradient(to bottom, #afafaf, #afafaf ); */
    /* background-color: #f2f2f2; */
    background-color: #f4f5fb;
    background-color: hsla(225, 30%, 95%, 1);
  }

  .bg-very-light-gray {
    background-color: hsla(225, 30%, 95%, 1);
  }
  .text-very-dark-gray {
    color:hsla(216, 85%, 25%, 1);
  }
  .text-dark-gray {
    color:hsla(216, 40%, 45%, 1);
  }
  .text-gray {
    color:hsla(216, 30%, 75%, 1);
  }

  .logo {
    /* font-family:Nunito, sans-serif; */
    font-size:5rem;
    line-height:4rem;
  }

  .tangocard-img {
    /* max-width: 30%; */
    height: 150px;
  }
  .tangocard-img-2 {
    /* max-width: 50%; */
    height: 150px;
  }
  .tangocard-caption {
    max-width: 80%;
    width: 600px;
  }

  .how-it-works-image {
    max-width: 100%;
    max-height: 250px;
  }
  .how-it-works-caption {
    padding-top: 10px;
    padding-left:10px;
    padding-right:10px;
    height: 100px;
  }

  /* mobile */
  @media only screen and (max-width: 700px) {

    .header {
      /* padding-top:50px; */
    }

    .logo {
      font-size:4rem;
      line-height:3rem;
    }

    .tangocard-img {
      /* width: 200px; */
    }

    .how-it-works-title {
      font-size: 22px !important;
    }

  }

</style>
