import forUser from '@/misc/firebase.for-user'

const state = {
  count:1,
}

const mutations = {
  increment(state) { state.count++; },
  setCount(state, value) { state.count = value; },

  loopLog(state, {value, i}) { console.log( value + ' ' + i ); state.count = i; },
}

const actions = {

  doLoopLogs({commit}, {value, loops}) {
    for(let i = 0; i < loops; i++) {
      commit('loopLog', {value, i});
    }
  },

  // '/test'
  // '/api/...'
  testRequest({getters}) {

    return forUser(getters.user, 'post', '/test', {
      email:'tomknappramos@gmail.com',
      firstGiftToken:'-LSb-7eU_x9AkCdDBrUKavPJAija5G_ROMl8EfEmaDSvFW1-y9',
    })
    .then((response) => {
      console.log( JSON.stringify(response.data, null, 2) );
    })
    .catch((error) => {
      let errorMessage = (error.response) ? error.response.data : error.message;
      console.log( error );
      console.log( errorMessage );
    });

  },

}

const getters = {
  count(state) { return state.count; },
}

export default {
	state,
	mutations,
	actions,
	getters
}
