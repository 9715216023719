
const state = {
  active:false,
  message:'',
  type:'success',
  dismissable:false,
  label:'',
  href:''
}

const mutations = {
  setBottomBarActive(state, value) { state.active = value; },
  setBottomBarType(state, value) { state.type = value; },
  setBottomBarMessage(state, value) { state.message = value; },
  setBottomDismissable(state, value) { state.dismissable = value; },
  setBottomLabel(state, value) { state.label = value; },
  setBottomHRef(state, value) { state.href = value; },
}

const actions = {

  toggleBottomBar({getters, commit}) {
    commit('setBottomBarActive', !getters.bottomBarActive);
  },

  showMessage({ commit }, { message, type, label, href}) {
    commit('setBottomBarMessage', message);
    commit('setBottomBarType', type);
    commit('setBottomBarActive', true);
    commit('setBottomLabel', label);
    commit('setBottomHRef', href);
    setTimeout(() => {
      commit('setBottomBarActive', false);
    }, 3000);
  },

  showLongMessage({ commit }, { message, type, label, href}) {
    commit('setBottomBarMessage', message);
    commit('setBottomBarType', type);
    commit('setBottomBarActive', true);
    commit('setBottomLabel', label);
    commit('setBottomHRef', href);
    setTimeout(() => {
      commit('setBottomDismissable', true);
    }, 3000);
    setTimeout(() => {
      commit('setBottomBarActive', false);
    }, 60000);
  },

  showStickyMessage({ commit }, { message, type, label, href }) {
    commit('setBottomBarMessage', message);
    commit('setBottomBarType', type);
    commit('setBottomBarActive', true);
    commit('setBottomLabel', label);
    commit('setBottomHRef', href);
    commit('setBottomDismissable', true);
  },
}

const getters = {
  bottomBarActive(state) { return state.active; },
  bottomBarType(state) { return state.type; },
  bottomBarMessage(state) { return state.message; },
  bottomDismissable(state) { return state.dismissable; },
  bottomLabel(state) { return state.label; },
  bottomHRef(state) { return state.href; },
}

export default {
	state,
	mutations,
	actions,
	getters
}
