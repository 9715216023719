export default {

	// used on home page top bar ~ I think??
	itemsHome: [
		{ icon: 'info', title: 'Send Gift', route: '/gift-content-type' },
		{ icon: 'info', title: 'Blog', route: '/blog' },
		// UNUSED
		// { icon: 'info', title: 'About', route: '/about' },
	],

	// used by default on all other pages ~ in the top bar
	items:[
		{ icon: 'info', title: 'Send Gift', route: '/gift-content-type' },
		{ icon: 'info', title: 'Blog', route: '/blog' },
		// UNUSED
		// { icon: 'info', title: 'About', route: '/about' },
		// { icon: 'info', title: 'Cart', route: '/cart' },
		// { icon: 'info', title: 'Customizer', route: '/customizer' },
		// { icon: 'info', title: 'Google', link: 'http://google.com' },
	],

	// used in side drawer
	itemsAll:[
		{ icon: 'home', title: 'Home', route: '/' },
		{ icon: 'info', title: 'Send Gift', route: '/gift-content-type' },
		// { icon: 'info', title: 'About', route: '/about' },
		{ icon: 'info', title: 'Cart', route: '/cart' },
		{ icon: 'info', title: 'Blog', route: '/blog' },
		{ icon: 'info', title: 'Log in', route: '/login' },
		{ icon: 'info', title: 'Sign up', route: '/signup' },
	],

	// used when user is signed in and email verified
	itemsUser:[
		{ icon: 'home', title: 'Home', route: '/' },
		{ icon: 'info', title: 'Send Gift', route: '/gift-content-type' },
		{ icon: 'info', title: 'Cart', route: '/cart' },
		{ icon: 'info', title: 'Your Gifts', route: '/gifts' },
		{ icon: 'info', title: 'Blog', route: '/blog' },
	],

}

/*
Yak:

- spinner while gift UX is loading
- spinny gift

EZ:
- visa and master card logos at checkout

- claims on website:
	- stripe payment claim
	- non-refundable claim

ART:
- all gift customization art assets

---

/ free customizer flow
/ free gift server validation

/ redirct on checkout success w/ spinner

/ gift UX: facebook share
	/ serve gift with node encoded gift link
	/ make server urls: fb share descriptions

/ progress loader on gift screen

/ stripe fraud

/ checkout calculation - revise credits used for below 50 cents

/ emails ~ actual times ~ the one year thing sucks but oh well...

/? stripe must be at least 50 cents ~ make sure that amount isn't less than that...
^
Is in the client? or in the server? or both?

/ also add 3 cents for extra rules
/ stripe receipt email not sending. fix it. ~ it gets sent in production mode. would like to see it in dashboard too... oh well. :)

*/
