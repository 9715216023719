import Gx3d from '@/libs/gx3d'

const main = {
  instance: new Gx3d(),  // instance
  home: 'gx3d-home',     // DOM element the instance should be returned to when not active
};

const screenshot = {
  instance: new Gx3d(),
  home: 'gx3d-screenshot-home',
};

// TEMP
// debug
window.gx3d = main.instance;

////////////////////////////////////////////////////////////////
// configure

main.instance.setManualUpdate(true);

screenshot.instance.setAudioEnabled(false);
screenshot.instance.setScreenshotMode(true);
screenshot.instance.setManualUpdate(true);

////////////////////////////////////////////////////////////////
// start

main.instance.startInsideElement(main.home);
screenshot.instance.startInsideElement(screenshot.home);

////////////////////////////////////////////////////////////////

export default {
  main,
  screenshot,
};
