// unused ~ using checkout module + stripe lib for everything now.

import debug from '@/util/debug'

import axios from 'axios'

const state = {
}

const mutations = {
}

const actions = {

  doStripeCharge({getters}, stripeToken) {

    if(getters.user) {
      let firstGiftToken = getters.firstGiftToken;
      let checkoutEmail = getters.checkoutEmail;

      return getters.user.getIdToken(true)
      .then((token) => {
        let o = {
          firstGiftToken:firstGiftToken,
          checkoutEmail:checkoutEmail,
          token:token,
        };
        if(stripeToken) {
          o.stripeToken = stripeToken.id;
          o.stripeEmail = stripeToken.email;
        }
        return axios.post('/api/charge', o);
      });

    }else{
      debug.log( 'doStripeCharge: no user available for cart' );
      return Promise.reject(new Error('no user'));
    }

  },

  // calls /api/charge/update and returns the client secret from server
  doStripeIntentCharge({getters}) {
    return new Promise((resolve) => {
      if(getters.user) {

        getters.user.getIdToken(true)
        .then((token) => {

          let o = { token: token };
          axios.post('/api/charge/update', o)
          .then(response => { resolve(response.data); });  // response.data ~ client secret

        })

      }
    });
  },

}

const getters = {
}

export default {
	state,
	mutations,
	actions,
	getters
}
