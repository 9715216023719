
function fileToDataURL(file, keepHeader) {
	return new Promise((resolve) => {
		let reader = new FileReader();
		reader.onload = (e) => {
			let dataURL = e.target.result;
			if(!keepHeader) { dataURL = dataURL.split(';base64,').pop(); } // remove header
			resolve(dataURL);
		};
		reader.readAsDataURL(file);
	});
}

function dataURLtoBlob(dataURL) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURL.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataURL.split(',')[1]);
    else
        byteString = unescape(dataURL.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], {type:mimeString});
}

export default {
	fileToDataURL,
	dataURLtoBlob
}
