import debug from '@/util/debug'

import firebase from 'firebase/app'

import forUser from '@/misc/firebase.for-user'

// ------------------------------------ //
// functions

function addUserItemThenRetreiveCart(user, item, files, captcha, dispatch) {
  let response;
  return forUser(user, 'post', '/api/cart', {item:item, files:files, captcha:captcha})
  .then((_response) => {
    response = _response;
    debug.log( response );
    debug.log('addCartItem: successfully added item to cart. retrieving cart...');
    return Promise.resolve();
  })
  .then(() => dispatch('retrieveCart'))
  .then(() => Promise.resolve(response));
}

function finalizeUserItemThenRetreiveCart(user, item, key, dispatch) {

  // util for emitting cart status
  function emitStatus(status, percent) { dispatch('cartProgress', {text:status, value:percent}); }

  return forUser(user, 'post', '/api/cart/finalize', {item:item, key:key})
  .then(() => {
    emitStatus('Successfully updated remote cart', 80);
    debug.log('addCartItem: successfully finalized item in cart. retrieving cart...');
    return Promise.resolve();
  })
  .then(() => {
    emitStatus('Retreiving updated cart', 100);
    return dispatch('retrieveCart');
  })
}

function removeCartItemThenRetreiveCart(user, key, dispatch) {
  return forUser(user, 'post', '/api/cart/remove', {key:key})
  .then(() => { debug.log('removeCartItem: successfully removed item in cart. retrieving cart...'); })
  .then(() => dispatch('retrieveCart'))
  .then(() => Promise.resolve());
}

// server/client must match
function computeCheckoutTotals(items, firstGiftToken, credits) {

  // cart
  let startTotal = 0;           // total starting dollars (without processing fee)
  let contentTotal = 0;         // total cost of gift content
  let wrapTotal = 0;            // dollars generated from the wraps
  let creditsDeduction = 0;
  let creditsUsed = 0;
  let firstGiftTokenDeduction = 0;
  let afterTotal = 0;
  let stripeFee = 0;
  let processingFee = 0;
  let total = 0;
  let creditsBackProcessingFee = 0;
  let creditsBackWraps = 0;
  let creditsBackOffset = 0;
  let offsetDiff = 0;
  let offsetSpill = 0;
  let creditsBack = 0;

  // per-item
  let itemsTotals = {};
  let accumFirstGiftToken = (firstGiftToken) ? 0.99 : 0;
  let accumCredits        = (credits) ? credits/100 : 0;

  // ------------------ //
  // per-item values

  for(let i in items) {
    let item = items[i];
    if(item.customizationMode === 'personal') {
      startTotal += 0.99;

      // create entry in per-gift totals object
      if(!(i in itemsTotals)) { itemsTotals[i] = {}; }
      let itemTotals = itemsTotals[i];

      // accum total
      let itemTotal = 0.0;

      // payment from firstGiftToken
      if(accumFirstGiftToken > 0.0 && itemTotal < 0.989999) {
        firstGiftTokenDeduction = 0.99;

        accumFirstGiftToken = 0;           // update accumulated values
        itemTotals.firstGiftToken = 0.99;  // update item
        itemTotal += 0.99;                 // update gift total so far
      }

      // payment from credits
      if(accumCredits > 0.0 && itemTotal < 0.989999) {
        let creditsToUse;
        if(accumCredits >= 0.99-itemTotal) { creditsToUse = dollar(0.99-itemTotal); }
        else{ creditsToUse = accumCredits; }

        // update overall credits to use
        creditsDeduction += creditsToUse;
        creditsUsed += Math.round(creditsToUse*100);

        accumCredits -= creditsToUse;       // update accumulated values
        itemTotals.credits = creditsToUse;  // update item
        itemTotal += creditsToUse;          // update gift total so far
      }

      // payment from actual money
      if(itemTotal < 0.989999) {
        let moneyToUse = dollar(0.99 - itemTotal);
        wrapTotal += moneyToUse;

        // add creditsBack to gift
        itemTotals.creditsBack = Math.round( moneyToUse/(0.99)*(15) );
        creditsBackWraps += itemTotals.creditsBack;

        itemTotals.dollars = moneyToUse;  // update item
        itemTotal += moneyToUse;          // update gift total so far
      }

    }
    if( item.contentType === 'tango-card' ) {
      startTotal += item.contentInfo.amount;
      contentTotal += item.contentInfo.amount;

      // create entry in per-gift totals object
      if(!(i in itemsTotals)) { itemsTotals[i] = {}; }
      let itemTotals = itemsTotals[i];
      itemTotals.content = item.contentInfo.amount;

    }
  }

  // ------------------ //
  // after-deduction values

  startTotal   = dollar( startTotal );
  contentTotal = dollar( contentTotal );
  wrapTotal    = dollar( wrapTotal );

  afterTotal = dollar( contentTotal + wrapTotal );

  // processing fee
  stripeFee = dollarCeil( 0.3 + afterTotal*(0.029) + 0.02 );  // +2 cents Radar for Fraud Teams
  if(afterTotal < 0.0000001) stripeFee = 0;
  processingFee = dollar( stripeFee - wrapTotal );
  if(processingFee < 0.0000001) processingFee = 0;

  // total ~ offset when less than 50 cents
  total = dollar( afterTotal + processingFee );
  if(total < 0.5 && total > 0.0000001) {

    // diff on total and give back credit spill
    offsetDiff  = dollar(0.5 - total);
    offsetSpill = ( creditsDeduction > 0.49 ) ? dollar(creditsDeduction - 0.49) : 0;

    // credits given back ~ offsets for extra paid credits + the added amount
    creditsBackOffset = Math.round((offsetDiff + offsetSpill)*100);

    // force total
    total = 0.5;
  }

  // credits back
  creditsBackProcessingFee = Math.round( processingFee*100*(0.5) );
  creditsBack = creditsBackProcessingFee + creditsBackWraps + creditsBackOffset;

  function dollar(value) { return Math.round( value*100 ) / 100; }
  function dollarCeil(value) { return Math.ceil( value*100 ) / 100; }

  // ------------------ //
  // return

  return {
    itemsTotals:itemsTotals,
    startTotal:startTotal,
    contentTotal:contentTotal,
    wrapTotal:wrapTotal,
    creditsDeduction:creditsDeduction,
    firstGiftTokenDeduction:firstGiftTokenDeduction,
    afterTotal:afterTotal,
    stripeFee:stripeFee,
    processingFee:processingFee,
    total:total,
    creditsUsed:creditsUsed,
    creditsBack:creditsBack,
    creditsBackProcessingFee:creditsBackProcessingFee,
    creditsBackWraps:creditsBackWraps,
    creditsBackOffset:creditsBackOffset,
    offsetDiff:offsetDiff,
    offsetSpill:offsetSpill,
  };

}

// ------------------------------------ //

const state = {
  items:null,  // object directly from DB ~ firebase keys are chronological by default

  startTotal:0,            // total without credits or free-tokens
  wrapTotal:0,            // total made from wraps (after deductions)
  creditsDeduction:0,      // from admin ~ Q: need to update admin credits --> admin module needs an update
  firstGiftTokenDeduction:0,    // from url arg ~ token needs to be verified upon opening page & saved to session storage ~ I think I should make a module for it...
  afterTotal:0,
  stripeFee:0,         // calculated based on afterTotal
  processingFee:0,         // calculated based on afterTotal
  total:0,               // ending total
  creditsBack:0,           // credits given back on processing fee ~ after purchase

  progressText:'',
  progressValue:0,

}

const mutations = {
  setCartItems(state, items) { state.items = items; },

  setCartStartTotal(state, val)              { state.startTotal = val; },
  setCartWrapTotal(state, val)               { state.wrapTotal = val; },
  setCartCreditsDeduction(state, val)        { state.creditsDeduction = val; },
  setCartFirstGiftTokenDeduction(state, val) { state.firstGiftTokenDeduction = val; },
  setCartAfterTotal(state, val)              { state.afterTotal = val; },
  setCartStripeFee(state, val)               { state.stripeFee = val; },
  setCartProcessingFee(state, val)           { state.processingFee = val; },
  setCartTotal(state, val)                   { state.total = val; },
  setCartCreditsBack(state, val)             { state.creditsBack = val; },

  setCartProgressText(state,val) { state.progressText = val; },
  setCartProgressValue(state,val) { state.progressValue = val; },
}

const actions = {

  cartProgress({commit}, {text, value}) {
    commit('setCartProgressText', text);
    commit('setCartProgressValue', value);
  },

  pingCart({getters, dispatch}) {
    if( !getters.cartItems ) {
      return dispatch('retrieveCart');
    }
  },

  retrieveCart({getters, commit, dispatch}) {
    let db = firebase.database();
    if(getters.user) {
      return db.ref('/carts/' + getters.user.uid).once('value')
      .then((snapshot) => {
        debug.log('retrieveCart: successfully retrieved cart.');
        let cart = snapshot.val();
        let items = (cart) ? cart.items : {};
        commit('setCartItems', items);
        dispatch('updateCartTotals');
        return Promise.resolve(items);
      });
    }else{
      debug.log('retrieveCart: No user found. Cannot retrieve cart.');
      return Promise.resolve(null);
    }
  },

  addCartItem({getters, dispatch}, {item, files, captcha}) {

    if(getters.user) {
      debug.log('addCartItem: Adding item to cart.');
      return addUserItemThenRetreiveCart(getters.user, item, files, captcha, dispatch);
    }else{
      debug.log('addCartItem: No user found. Pinging auth and trying again.');
      return dispatch('pingAuth').then(() => {
        return addUserItemThenRetreiveCart(getters.user, item, files, captcha, dispatch);
      });
    }

  },

  finalizeCartItem({getters, dispatch}, {item, key}) {

    if(getters.user) {
      debug.log('addCartItem: Adding item to cart.');
      return finalizeUserItemThenRetreiveCart(getters.user, item, key, dispatch);
    }else{
      debug.log('addCartItem: No user found. Pinging auth and trying again.');
      return dispatch('pingAuth').then(() => {
        return finalizeUserItemThenRetreiveCart(getters.user, item, key, dispatch);
      });
    }

  },

  removeCartItem({getters, dispatch}, key) {

    if(getters.user) {
      debug.log('removeCartItem: remove item from cart.');
      return removeCartItemThenRetreiveCart(getters.user, key, dispatch);
    }else{
      debug.log('removeCartItem: No user found.');
    }

  },

  clearCart({commit, dispatch}) {
    commit('setCartItems', null);
    dispatch('updateCartTotals');
  },

  updateCartTotals({state, getters, commit}) {

    let items = state.items;
    let firstGiftToken = getters.firstGiftToken;
    let credits = (getters.admin) ? getters.admin.credits : null ;

    // compute
    let totals = computeCheckoutTotals(items, firstGiftToken, credits);
    debug.log( 'totals:' );
    debug.log( totals );

    // commit calculations
    commit('setCartStartTotal',              totals.startTotal);
    commit('setCartWrapTotal',               totals.wrapTotal);
    commit('setCartCreditsDeduction',        totals.creditsDeduction);
    commit('setCartFirstGiftTokenDeduction', totals.firstGiftTokenDeduction);
    commit('setCartAfterTotal',              totals.afterTotal);
    commit('setCartStripeFee',               totals.stripeFee);
    commit('setCartProcessingFee',           totals.processingFee);
    commit('setCartTotal',                   totals.total);
    commit('setCartCreditsBack',             totals.creditsBack);

  },

}

const getters = {
  cartItems(state) {return state.items},

  cartStartTotal(state)              { return state.startTotal },
  cartWrapTotal(state)               { return state.wrapTotal },
  cartCreditsDeduction(state)        { return state.creditsDeduction },
  cartFirstGiftTokenDeduction(state) { return state.firstGiftTokenDeduction },
  cartAfterTotal(state)              { return state.afterTotal },
  cartStripeFee(state)               { return state.stripeFee },
  cartProcessingFee(state)           { return state.processingFee },
  cartTotal(state)                   { return state.total },
  cartCreditsBack(state)             { return state.creditsBack },

  cartCaptchaNeededForAdd(state) {
    let numItems = (state.items) ? Object.keys(state.items).length : 0;

    // first cart gift and every 10th gift after that
    return (numItems % 10 == 0);
  },

  cartProgressText(state) { return state.progressText },
  cartProgressValue(state) { return state.progressValue },

}

export default {
	state,
	mutations,
	actions,
	getters
}

// ------------------------------------ //
// old compute checkout total function ~ doing bulk calculations rather than per-item
/*
function computeCheckoutTotalsOld(items, firstGiftToken, credits) {

  let wrapTotal = 0;            // money generated from the actual wraps
  let contentTotal = 0;         // total cost of gift content
  let startTotal = 0;
  let creditsDeduction = 0;
  let firstGiftTokenDeduction = 0;
  let afterTotal = 0;
  let stripeFee = 0;
  let processingFee = 0;
  let total = 0;
  let creditsBack = 0;

  // -------------------//
  // raw totals

  // compute wrapTotal
  for(let i in items) {
    let item = items[i];
    if(item.customizationMode === 'personal') {
      wrapTotal += 0.99;
    }
  }

  // starting total
  startTotal = contentTotal + wrapTotal;

  // -------------------//
  // deductions on wraps

  // free-token deduction
  if(firstGiftToken) {
    firstGiftTokenDeduction = 0.99;
    if( wrapTotal <= firstGiftTokenDeduction ) { firstGiftTokenDeduction = wrapTotal; }
    wrapTotal -= firstGiftTokenDeduction;
  }

  // credits deduction on wraps
  if( credits ) {
    creditsDeduction = credits*(0.01);
    if( wrapTotal <= creditsDeduction ) { creditsDeduction = wrapTotal; }
    wrapTotal -= creditsDeduction;
  }

  // -------------------//
  // after deductions and processing

  afterTotal = contentTotal + wrapTotal;

  // processing fee
  stripeFee = 0.3 + afterTotal*(0.029);
  processingFee = stripeFee - wrapTotal;
  if(processingFee<0) processingFee = 0;

  // total
  total = afterTotal + processingFee;

  // creditsBack ~ processingFee return + paid wrap return
  creditsBack = Math.round( processingFee*100*(0.5) + wrapTotal/(0.99)*(15) );


  // --------------- //
  // simulate per-item checkout

  let gifts = {};

  let accumFirstGiftToken = firstGiftTokenDeduction;
  let accumCredits        = creditsDeduction;
  let accumWrap           = wrapTotal;

  let accumCreditsBack    = creditsBack;

  debug.log( 'Accumulated Values Start:' );
  debug.log(accumFirstGiftToken);
  debug.log(accumCredits       );
  debug.log(accumWrap          );

  for(let i in items) {
    let item = items[i];

    // create fake published gift
    gifts[i] = {};
    let gift = gifts[i];

    if(item.customizationMode === 'personal') {
      let itemTotal = 0.0;

      // payment from firstGiftToken
      if(accumFirstGiftToken > 0.0 && itemTotal < 0.989999) {
        accumFirstGiftToken = 0;         // update accumulated values
        gift.firstGiftTokenUsed = 0.99;  // update item
        itemTotal += 0.99;               // update gift total so far
      }

      // payment from credits
      if(accumCredits > 0.0 && itemTotal < 0.989999) {
        let creditsToUse;
        if(accumCredits >= 0.99-itemTotal) {
          creditsToUse = 0.99-itemTotal;
        }else{
          creditsToUse = accumCredits;
        }
        accumCredits -= creditsToUse;     // update accumulated values
        gift.creditsUsed = creditsToUse;  // update item
        itemTotal += creditsToUse;        // update gift total so far
      }

      // payment from actual money
      if(itemTotal < 0.989999) {
        let moneyToUse = 0.99 - itemTotal;

        // add creditsBack to gift
        gift.senderCredits = moneyToUse/(0.99)*(15);

        accumWrap -= moneyToUse;              // update accumulated values
        gift.amountPaid = dollar(moneyToUse);  // update item
        itemTotal += moneyToUse;              // update gift total so far
      }

    }
  }

  debug.log( 'Accumulated Values:' );
  debug.log(accumFirstGiftToken);
  debug.log(accumCredits       );
  debug.log(accumWrap          );
  debug.log(accumWrap <= 0.0001);

  // show gifts
  debug.log( JSON.stringify(gifts,null,2) );

  // checks
  let creditsDeductionFromGifts = 0;
  for(let i in gifts) {
    let gift = gifts[i];
    if(gift.creditsUsed)
      creditsDeductionFromGifts += Math.round(gift.creditsUsed*100);
  }

  let creditsBackFromGifts = Math.round( processingFee*100*(0.5) );
  for(let i in gifts) {
    let gift = gifts[i];
    if(gift.senderCredits)
      creditsBackFromGifts += gift.senderCredits;
  }



  debug.log( 'creditsDeductionFromGifts' );
  debug.log( creditsDeductionFromGifts );

  debug.log( 'creditsBackFromGifts' );
  debug.log( creditsBackFromGifts );

  function dollar(value) { return Math.round(value*100)/100; }
  //function cents(value) { return Math.round(value*100); }

  // --------------- //


  return {
    wrapTotal:wrapTotal,
    contentTotal:contentTotal,
    startTotal:startTotal,
    creditsDeduction:creditsDeduction,
    firstGiftTokenDeduction:firstGiftTokenDeduction,
    afterTotal:afterTotal,
    stripeFee:stripeFee,
    processingFee:processingFee,
    total:total,
    creditsBack:creditsBack,
  };

}
*/
