import '@babel/polyfill'
import Vue from 'vue'
import './plugins/vuetify'
import './plugins/vue-stripe-checkout'
import App from './App.vue'
import router from './router'
import store from './store/store'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'

import firebase from './libs/firebase'
import './libs/firebaseui'

import './libs/gx3ds'

Vue.config.productionTip = false;

switch (process.env.NODE_ENV) {
  case 'production': break;
  case 'development': console.log('DEV MODE'); break;
  default: console.log('No env mode. See main.js and libs/firebase.js.');
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');

firebase.auth().onAuthStateChanged((user) => {
  store.dispatch('authStateChange', user);
});
