// customizer static content

// gift content types ~ tango-card, pdf, link, image
export const contentTypes = [
	{ title: 'Swipewrap only', value: 'link' , img: '/images/logo-small-padded.png', btn:'Send a Swipewrap', description:'Just looking to show someone you care? Send a swipewrap with just the personally written card!'},
	{ title: 'Tango Card', value: 'tango-card', img: '/images/tangocard.png', btn:'Send a Tango Card', description:'A Tango Card is a gift card that lets your recipient select which store they want credit towards' },
	{ title: 'PDF', value: 'pdf' , img: '/images/pdf-icon.png', btn:'Send a PDF', description:'Upload your event tickets or travel bookings and delivery them digitally wrapped with swipewrap'},
	{ title: 'Image', value: 'image' , img: '/images/image_icon.png', btn:'Send an Image', description:'Have an image file representing your gift? Upload your image and deliver it digitally wrapped with swipewrap'},
	{ title: 'Link', value: 'link' , img: '/images/link_square.png', btn:'Send a Link', description:'Share a link to your gift wrapped with swipewrap'},
];

export const deliveryOptions = [
	{ title: 'Email', caption:'We will deliver your gift for you (Please allow up to 30 minutes for gift to deliver)', value: 'email' },
	{ title: 'Link', caption: 'We create a unique gift link that you can send yourself', value: 'link' },
];

export const timezones = [
	'Pacific/Honolulu'   ,
	'America/Los_Angeles',
	'America/Denver'     ,
	'America/Chicago'    ,
	'America/New_York'   ,
];

// HERE - max file upload size 1.5 MB ~~~ typical image file size is 6 MB

// NOTE: a typical max upload size is 20 MB

// max file upload size
export const MAX_FILE_UPLOAD_SIZE =  1500000; //1800000;  // 1.5 MB  // TODO: determine a good amount ~ using local storage to save files which is 4.5 MB.

// max tango card amount
export const MAX_TANGO_CARD_AMOUNT = 100;
export const MIN_TANGO_CARD_AMOUNT = 1;

// -------------------------------------- //
// bottom steps

// main steps (new gift mode)
export const steps = [
	{ title: 'Gift'  , icon: 'info' },
	{ title: 'Box'   , icon: 'card_giftcard' },
	{ title: 'Card'  , icon: 'create' },
	{ title: 'Review', icon: 'send' }
];

// main steps (edit gift mode)
export const stepsEdit = [
	{ title: 'Box'   , icon: 'card_giftcard' },
	{ title: 'Card'  , icon: 'create' },
	{ title: 'Done'  , icon: 'send' }
];

// -------------------------------------- //
// gift object

// default settings for new gifts + basis for gifts staged from database.
export const defaultGift = {

	senderEmail:null,    // optional ~ login or checkout or inputed. or none
	receiverEmail:null,  // optional ~ inputed or given after opened. or none
	senderName: null,
  receiverName: null,
	anonymous: false,

	contentType:'tango-card',  // 'tango-card', 'link', 'pdf', 'youtube', ...
	contentInfo:null,  // card value, link url, pdf url, ~ depends on type

	deliveryType:'email',   // 'email', 'link'
	deliveryTime:null,      // delivery timestamp (null if now is desired or using link)
	deliveryTimezone:null,  // for customizer

	screenshot: null,
	screenshotWide: null,

	customizationMode:'personal',  // 'personal' or 'free' (should be in db)

	// customization objects ~ depending on mode
	shared: {
		card: {
			front: {text: null},
			inside: {text: null},
			signature: {text: null}
		}
	},

	personal: {

		preset: null,   // reference to preset if used (a customization or custset is a non-saved preset)
		prewrap: null,  // reference to prewrap if used (a custwrap is a non-saved prewrap)

		presetEdited: false,   // flag if loaded preset was at all edited ~ new customization is created if so
		prewrapEdited: false,  // flag if loaded prewrap was at all edited ~ new custwrap is created if so

		builder: null,     // builder data ~ loaded in from prewrap
		usingCustomWrap: false,  // needed for customizer flow

		wrap:{src:null, repeat:null},         // wrap src and tile size
		ribbon:null,                          // ribbon color
		color:null,                           // background color
		texture: {src:null, opacity:null, color:null},  // background (B3 next version)
		back: {src:null, opacity:null, color:null},     // above background (B3 next version)
		front: {src:null, opacity:null, color:null},    // above box (B3 next version)

		card: {
			front: {
				//text: null,  // shared
				fontColor:null,
				fontFamily:null,
				fontSize:null,    // ~ set from font selection
				lineHeight:null,   // ~ set from font selection
				color: null,      // background color
			},
			inside: {
				//text: null,  // shared
				fontColor:null,
				fontFamily:null,
				fontSize:null,    // ~ set from font selection
				lineHeight:null,   // ~ set from font selection
				color: null,      // background color
			},
			signature: {
				//text: ''  // shared
			}
		}

	},

	free: {
		wrap:{src:null, repeat:null},  // wrap.src ~ a swipewrap branded wrapping paper (might just do one color)
		// card: {                  // shared
			// front: {text: ''},     // shared
			// inside: {text: ''},    // shared
			// signature: {text: ''}  // shared
		// }
	}

};

// display customizations when gift values are null/undefined
// NOTE: must match public file
export const B3_DEFAULTS = {

  cardDetails: {
    cardBackgroundColor: '#FF7E5E',
    cardBackgroundImageURL: null,
    frontCoverBackgroundColor: '#af2828',
    backCoverBackgroundColor: '#FFBBAA',
		insideLeftBackgroundColor: '#ffbdbd',
    insideRightBackgroundColor: '#ffbdbd',
    textContent: {
      title: {
        text: 'Hello',
        color: '#FFFFFF',
        fontSize: 95,
        fontFamily: 'Gloria Hallelujah',
        lineHeight: 1.5,
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        mapRegion: [720, 1046, 1315, 1950]
      },
      back: {
        text: 'Make your own virtual gift at:\nswipewrap.com',
        color: '#0099FF',
        fontSize: 36,
        fontFamily: 'Quicksand',
        lineHeight: 2,
        horizontalAlignment: 'center',
        verticalAlignment: 'bottom',
        mapRegion: [27, 1026, 660, 1970]
      },
      inside: {
        text: 'Thank you for using \nSwipewrap!',
        color: '#641717',
        fontSize: 90,
        fontFamily: 'Gloria Hallelujah',
        lineHeight: 1.5,
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        mapRegion: [57, 57, 1305, 792]
      },
      signature: {
        text: 'Sincerely,\nThe Swipewrap Team',
        color: '#641717',
        fontSize: 45,
        fontFamily: 'Gloria Hallelujah',
        lineHeight: 2,
        horizontalAlignment: 'left',
        verticalAlignment: 'bottom',
        mapRegion: [730, 57, 1305, 942]
      }
    }
  },

  giftwrapTextureURL: '/assets/wraps/logo.jpg', // '/assets/giftwrap.jpg',
  giftwrapTextureRepeat: 4,

  ribbonColor: '#FFFFFF',

  sceneBackgroundColor: '#2F2F2F', //'#CCCCCC', // '#000000',
  sceneBackgroundImageURL: '/assets/textures/logo.png', //'/assets/christmas.png', // '/assets/shine.png', //'/assets/christmas.png',
  sceneBackgroundImageTiling : 3,
  sceneBackgroundImageStretch: false,
  sceneBackgroundImageOpacity: 0.05,
  sceneBackImageURL: '/assets/backs/radial_gradient_white.png',
  sceneBackImageTiling: 1,
  sceneBackImageStretch: true,
  sceneBackImageOpacity: 0.1,
  sceneFrontImageURL: null, //'/assets/shine.png', //'/assets/fronts/branches.png', //'/assets/shine.png',
  sceneFrontImageTiling: 1,
  sceneFrontImageStretch: false,
  sceneFrontImageOpacity: 1,

}

// -------------------------------------- //
// builder

// builder content
export const builder = {

	main:{

		actions:[
			{ title:'Add Image', icon:'perm_media', event:'image' },
			//{ title:'Insert Art', icon:'bubble_chart', event:'insert_art' },
			{ title:'Color', icon:'color_lens', event:'color' },
			{ title:'Texture', icon:'texture', event:'texture' }
		]

	},

	crop:{

		actions:[
			{ title:'Reset', icon:'crop', event:'reset' },
			{ title:'Select Region', icon:'account_box', event:'region' }
		]

	},

	region:{

		actions:[
			{ title:'Size', icon:'fiber_manual_record', event:'size' },
			{ title:'Undo', icon:'undo ', event:'undo' }
		]

	},

};

// builder image-to-screen ratio
export const ITEM_SCREEN_RATIO = 0.45;

// -------------------------------------- //
// gift customizations

export const fronts = [
	'/assets/fronts/shine.png',
	'/assets/fronts/lens_flare_white.png',
	'/assets/fronts/snow.png',
	'/assets/fronts/snow_2.png',
	'/assets/fronts/snow_2_1.png',
];

export const backs = [
	'/assets/backs/radial_gradient_white.png',
	'/assets/backs/radial_gradient.png',
	'/assets/backs/vertical_gradient_white.png',
	'/assets/backs/vertical_gradient.png',
];

export const textures = [
	'/assets/textures/cheeta.png',
	'/assets/textures/christmas.png',
	'/assets/textures/christmas_black.png',
	'/assets/textures/dots.png',
	'/assets/textures/dots_black.png',
	'/assets/textures/dots_white.png',
	'/assets/textures/hearts.png',
	'/assets/textures/hearts_black.png',
	'/assets/textures/logo.png',
	'/assets/textures/paws.png',
	'/assets/textures/paws_black.png',
	'/assets/textures/stripe.png',
	'/assets/textures/stripe_black.png',
	'/assets/textures/trees.png',
	'/assets/textures/trees_black.png',
];

export const wrapTextures = [
	'/assets/wrap-textures/christmas.png',
	'/assets/wrap-textures/dots.png',
	'/assets/wrap-textures/hearts.png',
	'/assets/wrap-textures/paws.png',
	'/assets/wrap-textures/stripe.png',
	'/assets/wrap-textures/trees.png',
];

export const wraps = [
	{ src:'/assets/wraps/chevron.jpg'			      , repeat:4 },
	{ src:'/assets/wraps/hearts.jpg'			      , repeat:4 },
	{ src:'/assets/wraps/wavy_rainbow.jpg'      , repeat:2 },
	{ src:'/assets/wraps/pink_plaid.jpg'		    , repeat:7 },
	{ src:'/assets/wraps/red_christmas.jpg'     , repeat:4 },
	{ src:'/assets/wraps/artsy.jpg'				      , repeat:7 },
	{ src:'/assets/wraps/blue_stripe.jpg'		    , repeat:4 },
	{ src:'/assets/wraps/pink_stripe.jpg'		    , repeat:4 },
	{ src:'/assets/wraps/cheeta.jpg'			      , repeat:5 },
	{ src:'/assets/wraps/circles.jpg'			      , repeat:3 },
	{ src:'/assets/wraps/diamond_cherry.jpg'    , repeat:8 },
	{ src:'/assets/wraps/fish.jpg'				      , repeat:5 },
	{ src:'/assets/wraps/green_christmas.jpg'	  , repeat:5 },
	{ src:'/assets/wraps/paws.jpg'				      , repeat:4 },
	{ src:'/assets/wraps/sparkles_silver.jpg'	  , repeat:2 },
	{ src:'/assets/wraps/logo.jpg'				      , repeat:4 } ,
];

export const wrapsFree = [
	{ src:'/assets/wraps-free/logo.jpg'         , repeat:4 } ,
	{ src:'/assets/wraps-free/logo-purple.jpg'  , repeat:4 } ,
	{ src:'/assets/wraps-free/logo-blue.jpg'    , repeat:4 } ,
	{ src:'/assets/wraps-free/logo-green.jpg'   , repeat:4 } ,
];

export const colors = [
	'#000000',
	'#ffffff',
	'#ff0000',
	'#c93452',
	'#88353c',
	'#0000ff',
	'#629dfd',
	'#0079db',
	'#000080',
	'#b1ff5d',
	'#00ec00',
	'#02a469',
	'#ffff00',
	'#f9f46f',
	'#ff7f41',
	'#ff00ff',
	'#ff76a0',
	'#7032b0',
	'#665542',
	'#d7bea3',
	'#d7d7d7',
	'#827f77',
	'#4f4f4f',
];

//export const ribbonColors

// sizes/lineheights are scalers ~ not pixels
export const fonts = [
	{ family:'Gloria Hallelujah', size:1,    lineHeight:1, sample:'Fun', },
	{ family:'Roboto',            size:1,    lineHeight:1, sample:'Simple', },
	{ family:'Source Serif Pro',  size:1.05, lineHeight:1, sample:'Clean', },
	{ family:'Allura',            size:1.3,  lineHeight:1, sample:'Ornate', },
	{ family:'Quicksand',         size:1,    lineHeight:1, sample:'Slick', },
	{ family:'Kranky',            size:1.1,  lineHeight:1, sample:'Playful', },
	{ family:'Audiowide',         size:1,    lineHeight:1, sample:'Zap', },
	{ family:'Caesar Dressing',   size:1,    lineHeight:1, sample:'Jungle', },
];

// -------------------------------------- //
// customizer menu flows

export const flowMapPersonal = {

	// gift step
  0:{
    0:{ next:{step:0, tab:1} },
    1:{ next:{step:1, tab:0, disable:true}, prev:{step:0, tab:0} },
  },

	// box step
  1:{
    0:{ next:{step:1, tab:1} },
    1:{ next:{step:1, tab:3}, prev:{step:1, tab:0} },  // wrap selection
		2:{ next:{step:1, tab:3}, prev:{step:1, tab:0} },  // tile size

		3:{ next:{step:1, tab:4, subTab:0}, prev:{step:1, tab:0} },

    4:{
      0: { next:{step:1, tab:4, subTab:1}, prev:{step:1, tab:3} },
      1: { next:{step:1, tab:5}, prev:{step:1, tab:4, subTab:0} },
    },
		5:{ next:{step:1, tab:6}, prev:{step:1, tab:4, subTab:1} },
		6:{ next:{step:2, tab:0}, prev:{step:1, tab:5} },
  },

	// card step
  2:{
    0:{ next:{step:2, tab:1, subTab:0} },
		1:{
			0: { next:{step:2, tab:1, subTab:1}, prev:{step:2, tab:0} },
			1: { next:{step:2, tab:2, subTab:0}, prev:{step:2, tab:1, subTab:0} },
		},
		2:{
			0: { next:{step:2, tab:2, subTab:1}, prev:{step:2, tab:1, subTab:1} },
			1: { next:{step:3, tab:0}, prev:{step:2, tab:2, subTab:0} },
		},
  },
  step: { init: {step:0, tab:0, subTab:0, disable:true} } // init: {step:0, tab:0}
};

export const flowMapPersonalNoTour = {
  0:{
  },
  1:{
    // 2: {
    //   1: { next:{step:3, tab:0} },
    // },
  },
  step:{
    0:{ step:0, tab:0, disable:true },
    1:{ step:1, tab:0, disable:true },
    2:{ step:2, tab:0, disable:true },
    3:{ step:3, tab:0, disable:true },
  }
};

export const flowMapFree = {

	// gift step
  0:{
    0:{ next:{step:0, tab:1} },
    1:{ next:{step:1, tab:1, disable:true}, prev:{step:0, tab:0} },
  },

	// box step (disabled)
  1:{
		1:{ next:{step:2, tab:0, disable:true}, prev:{step:0, tab:0} },
  },

	// card step
  2:{
    0:{ next:{step:3, tab:0, subTab:0} },
  },
  step: { init: {step:0, tab:0, subTab:0, disable:true} } // init: {step:0, tab:0}
};

export const flowMapFreeNoTour = {
  0:{
  },
  1:{
  },
  step:{
    0:{ step:0, tab:0, disable:true },
    1:{ step:1, tab:1, disable:true },
    2:{ step:2, tab:0, disable:true },
    3:{ step:3, tab:0, disable:true },
  }
};
