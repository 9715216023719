// management for the final checkout and CheckoutDone view

import debug from '@/util/debug'

import stripe from '@/libs/stripe'  // for handleCardPayment

import firebase from 'firebase/app'
import forUser from '@/misc/firebase.for-user'

import router from '@/router'

// ----------------------------------------- //
// watcher for db checkouts

let checkoutWatchRef = null;
let checkoutWatchContext = {};
const checkoutWatchHandler = function(childSnapshot) {
	let context = checkoutWatchContext;
	let data = childSnapshot.val();  // {giftInfos, credts, email}

	if(data) {
		debug.log('received checkout:');
		debug.log( JSON.stringify(data,null,2) );

		context.dispatch('refreshModules');
		context.commit('setCheckoutItems', data.giftInfos);
		context.commit('setCheckoutCreditsBack', data.credits);
		context.commit('setCheckoutCreditsBackEmail', data.email);
		router.push('/checkout-done');
	}
}

// ----------------------------------------- //

function getCheckoutEmail() {
	let email = sessionStorage.getItem('checkoutEmail');
	if(email === 'null') {
		email = null;
	}
	return email;
}

const state = {
	items:null,             // items displayed upon checkout
	creditsBack:null,       // credits back upon signing up
	creditsBackEmail:null,  // credits back

	processing:false, // button spinners

	email:getCheckoutEmail(),  // email used for freeCheckout
}

const mutations = {
	setCheckoutItems(state, data) { state.items = data; },
	setCheckoutCreditsBack(state, value) { state.creditsBack = value; },
	setCheckoutCreditsBackEmail(state, value) { state.creditsBackEmail = value; },

	setCheckoutEmail(state, value) {
		if(value==='') value=null;
		state.email = value;
		sessionStorage.setItem('checkoutEmail', value);
	},

	setCheckoutProcessing(state, value) { state.processing = value; },
}

const actions = {

	// new checkout function ~ handles free and paid checkouts
	checkout({getters, commit, dispatch}, elements) {

		if(!getters.checkoutProcessing) {
			commit('setCheckoutProcessing', true);

			debug.log('checkout: checking out...');

			// check user
			if(!getters.user) { debug.log( 'checkout: no user' ); return Promise.reject(new Error('no user')); }

			// paid checkout
			if( getters.cartTotal > 0.000001 ) {
				let firstGiftToken = getters.firstGiftToken;
				let stripeEmail;
				let secret;
				let pid;

				// promise chain...
				return Promise.resolve()

				// check for stripe elements
				.then(() => {
					if(!elements)                    { return Promise.reject(new Error('MISSING_PAYMENT_INFO')); }
					if(!elements.emailElement.value) { return Promise.reject(new Error('MISSING_PAYMENT_INFO')); }
				})

				// check for tango card availability ~ throw error if sold out
				.then(() => dispatch('checkTangoCardForCart'))
				.then((enough) => { if(!enough) { return Promise.reject(new Error('TANGO_CARDS_SOLD_OUT')); } })

				// save email to var
				.then(() => { stripeEmail = elements.emailElement.value; })

				// request a paymentIntent
				.then(() => {
					return forUser(getters.user, 'post', '/api/charge/request', {firstGiftToken:firstGiftToken, email:stripeEmail});
				})
				.then((response) => {
					secret = response.data.secret;
					pid = response.data.id
				})

				// perform stripe 3D Secure authorization, does NOT capture the payment yet
				// Capture of the payment will happen when fulfillment is complete
				.then(() => stripe.handleCardPayment(secret, elements.cardElement, {
							source_data: {
								owner: {
									email: stripeEmail,
								},
							},
							receipt_email: stripeEmail,
						}))

				.then((result) => {
					if (result.error) {
						// Display error.message in your UI.
						debug.log('handleCardPayment error:');
						debug.log(result.error);
		
						// Cancel the payment intent, so can't be used etc.
						dispatch('showStickyMessage', { type: 'error', message: `The payment failed, you have not been charged - ${result.error.message}` });

						setTimeout(() => {
							return forUser(getters.user, 'post', '/api/charge/cancel', {'id': pid })
								//.then(() => {
								//	dispatch('showLongMessage', { type: 'info', message: `We appologise, something went wrong with the payment system. The payment successfully cancelled : You have not been charged` });
								//})
								//.catch(() => {
								//	dispatch('showLongMessage', {
								//		type: 'error', message: `Payment NOT successfully cancelled : However, you have not been charged : contact `, label: 'support@swipewrap.com', href: 'mailto:support@swipewrap.com' });
								//})
						}, 100);



					} else {
						//dispatch('showMessage', { type: 'info', message: `handleCardPayment success` });

						// The payment has succeeded. Display a success message.
						debug.log('handleCardPayment success');
						debug.log('wait for checkout...');
						router.push('/checkout-success');
					}
					commit('setCheckoutProcessing', false);
				});

			}

			// free checkout. do a direct ping to api/charge/free.
			else{
				console.log('free checkout: direct ping to server.');

				let firstGiftToken = getters.firstGiftToken || null;
				let checkoutEmail = getters.checkoutEmail || null;
				return forUser(getters.user, 'post', '/api/charge/free', {firstGiftToken:firstGiftToken, checkoutEmail:checkoutEmail})
				.then(() => { commit('setCheckoutProcessing', false); });
			}

		}
	},

	// ------------------------- //
	// watcher enable/disable

	// watches db checkouts completed checkouts
	checkoutWatch({getters, commit, dispatch}, user) {
		user = user || getters.user;

		// clear previous
		if( checkoutWatchRef ) {
			checkoutWatchRef.off('child_changed', checkoutWatchHandler);
			checkoutWatchRef = null;
		}

		// vuex store access
		checkoutWatchContext.getters = getters;
		checkoutWatchContext.commit = commit;
		checkoutWatchContext.dispatch = dispatch;

		// start watcher
		checkoutWatchRef = firebase.database().ref('/checkouts/' + user.uid);
		checkoutWatchRef.on('child_changed', checkoutWatchHandler);

	},

	// clears the watcher
	checkoutClearWatch() {

		if( checkoutWatchRef ) {
			// stop watcher
			checkoutWatchRef.off('child_changed', checkoutWatchHandler);
			checkoutWatchRef = null;
		}

	},

	// ------------------------- //

	// checkout({commit, dispatch}, token) {
	//
	// 	debug.log('checkout: checking out...');
	// 	return dispatch('doStripeCharge', token)
	//
	// 	// set local checkout items
	// 	.then((response) => {
	// 		commit('setCheckoutItems', response.data);
	// 		return Promise.resolve();
	// 	})
	//
	// 	// checkout done actions. returned cart should be clear.
	// 	.then(() => {
	// 		debug.log('checkout: checkout done. refreshing modules.');
	// 		return dispatch('refreshModules');
	// 	})
	//
	// 	// return
	// 	.then(() => Promise.resolve());
	//
	// }

}

const getters = {
	checkoutItems(state) { return state.items },
	checkoutCreditsBack(state) { return state.creditsBack },
	checkoutCreditsBackEmail(state) { return state.creditsBackEmail },

	checkoutEmail(state) { return (state.email === '') ? null : state.email },

	checkoutProcessing(state) { return state.processing; },
}

export default {
	state,
	mutations,
	actions,
	getters
}
