import debug from '@/util/debug'

import fileData from '@/misc/file-data'

import heap from '@/libs/heap'

import { MIN_TANGO_CARD_AMOUNT, MAX_TANGO_CARD_AMOUNT } from '@/static/customizer'

// ----------------------------------------------- //
// module initialization management

export function refreshModules({dispatch}, skips) {
  if(!skips) skips = {};
  return Promise.resolve()
  .then(() => {
    if(!skips.all) {
      if(!skips.admin) {
        debug.log('global: refreshing modules');
        return dispatch('refreshAdmin');
      }
    }
  })
  .then(() => {
    let promises = [];
    if(!skips.all) {
      if(!skips.modules) {
        if(!skips.cart) promises.push(dispatch('retrieveCart'));
        if(!skips.gifts) promises.push(dispatch('retrieveGifts'));
      }
    }
    return Promise.all(promises);
  });
}


// clear user-related modules
export function clearModules({dispatch}) {
  debug.log('global: clearing modules');
  return Promise.resolve()
  .then(() => dispatch('clearAdmin'))
  .then(() => dispatch('clearCart'))
  .then(() => dispatch('clearGifts'));
}

export function pingModules({dispatch}, skips) {
  return Promise.resolve()
  .then(() => {
    if(!skips.all) {
      if(!skips.admin) {
        debug.log('global: pinging modules');
        return dispatch('pingAdmin');
      }
    }
    return Promise.resolve();
  })
  .then(() => {
    let promises = [];
    if(!skips.all) {
      if(!skips.modules) {
        if(!skips.cart) promises.push(dispatch('pingCart'));
        if(!skips.gifts) promises.push(dispatch('pingGifts'));
      }
    }
    return Promise.all(promises);
  });
}

// ----------------------------------------------- //
// main 'add to cart' action

export function addCustomizerStageToCart({getters, dispatch}) {
  let stage = getters.customizerStage;

  let captcha;
  let files = []; // files to upload ~ {dataURL, name, id}
  let cartKey;
  let uploads;
  let bin;

  // util for emitting cart status
  function emitStatus(status, percent) { dispatch('cartProgress', {text:status, value:percent}); }

  // promise chain...
  return Promise.resolve()

  // if recaptcha needed, check for token
  .then(() => {
    if(getters.cartCaptchaNeededForAdd) {
      captcha = getters.captchaToken('customizer');
      if(!captcha) {
        return Promise.reject(new Error('CAPTCHA_NEEDED'));
      }
    }
  })

  // tango card maximum enforcement
  .then(() => {
    if(stage.contentType === 'tango-card') {
      if(stage.contentInfo.amount > MAX_TANGO_CARD_AMOUNT) { return Promise.reject(new Error('MAX_TANGO_CARD_AMOUNT_EXCEEDED')); }
      if (stage.contentInfo.amount < MIN_TANGO_CARD_AMOUNT) { return Promise.reject(new Error('MIN_TANGO_CARD_AMOUNT_EXCEEDED')); }
    }
    // LATER (easy): minimum of 0 enforcement
  })

  // HERE - aggregate files from stage

  // get files inside gift and populate files obj
  .then(() => {
    emitStatus('Generating images', 20);
    let promises = [];

    // gift content
    let contentName;
    let contentFile;
    if(stage.contentInfo) {
      if(stage.contentInfo.file) {
        contentFile = heap.file(stage.contentInfo.file.url);
        contentName = stage.contentInfo.file.name;
        if(contentFile) {
          promises.push(
            fileData.fileToDataURL(contentFile.file)
            .then((dataURL) => {
              files.push( {file:dataURL, name:contentName, id:'content'} );
            })
          );
        }
      }
    }

    // custom wrap
    let wrapFile;
    if( stage.customizationMode === 'personal' ) {
      wrapFile = heap.file(stage.personal.wrap.src);
      if(wrapFile) {
        promises.push(
          fileData.fileToDataURL(wrapFile.file)
          .then((dataURL) => {
            files.push( {file:dataURL, name:'wrap.jpg', id:'wrap'} );
          })
        );
      }
    }

    // screenshot
    let screenshotFile;
    if(stage.screenshot) {
      screenshotFile = heap.file(stage.screenshot);
      if(screenshotFile) {
        promises.push(
          fileData.fileToDataURL(screenshotFile.file)
          .then((dataURL) => {
            files.push( {file:dataURL, name:'screenshot.jpg', id:'screenshot'} );
          })
        );
      }
    }

    // screenshot
    let screenshotWideFile;
    if(stage.screenshotWide) {
      screenshotWideFile = heap.file(stage.screenshotWide);
      if(screenshotWideFile) {
        promises.push(
          fileData.fileToDataURL(screenshotWideFile.file)
          .then((dataURL) => {
            files.push( {file:dataURL, name:'screenshotWide.jpg', id:'screenshotWide'} );
          })
        );
      }
    }

    return Promise.all(promises);
  })

  // Force an error here to check error handling
  //.then(() => { 
  //  emitStatus('Didnt Work Did it! HaHa', 20);
  //  return Promise.reject(new Error('Didnt Work Did it! HaHa!'))
  // })

  /*
   * add stage to cart with files
   * @returns {
   *   bin,
   *   cartKey,
   *   uploads:[ { id, binKey, name }, ... ]
   * }
   * OR
   * error ~ if failed verification
   */
  .then(() => {
    emitStatus('Adding to cart', 30);
    return dispatch('addCartItem', {item:stage, files:files, captcha:captcha});
  })

  // finalize db bin data
  .then((response) => {
    emitStatus('Uploading images to cart', 40);
    bin = response.data.bin;          // user bin
    cartKey = response.data.key;      // item key in cart
    uploads = response.data.uploads;  // [{binKey, name, id}]
    return dispatch('finalizeBinUploads', {bin:bin, uploads:uploads});

    // HERE -finalize bin uploads

    // Q: what was the reason for finalizeBinUploads?
    //  A: It is to get the public URL's for the files and then update the database bin management with the publicPath

  })

  // finalize cart item ~ update local paths to storage public paths first
  .then(() => {
    emitStatus('Updating remote images', 50);
    // uploads ~ [{binKey, name, id, publicPath}]

    // map: id --> publicPath
    let publicPaths = {};
    for(let i in uploads) {
      let file = uploads[i];
      publicPaths[file.id] = file.publicPath;
    }

    // debug
    // console.log('publicPaths:');
    // console.log(publicPaths);

    // set stage values if in map...
    if('wrap' in publicPaths)        { stage.personal.wrap.src    = publicPaths['wrap'];    }
    if('content' in publicPaths )    { stage.contentInfo.file.url = publicPaths['content']; }
    if('screenshot' in publicPaths ) { stage.screenshot = publicPaths['screenshot']; }
    if('screenshotWide' in publicPaths ) { stage.screenshotWide = publicPaths['screenshotWide']; }

    // add to cart?
    return dispatch('finalizeCartItem', {item:stage, key:cartKey});
  })



  // done. clear stage.
  .then(() => dispatch('clearCustomizerStage'));

}

// ----------------------------------------------- //
// old

// old add stage to cart function
/*
export function addCustomizerStageToCartOld({getters, dispatch}) {
  // current:
  // - upload files (this takes care of bin updates)
  // - update local gift to public URLs
  // - add to cart

  if( getters.customizerStageCallback ) {
    // not planning on editing gifts until later...
    return dispatch('runCustomizerStageCallback');  // unused for now
  }else{

    // debug
    // debug.log( 'Store: customizer stage,' );
    // debug.log( JSON.stringify(getters.customizerStage, null, 2) );

    // ------------------------------------ //
    // upload all local gift files and replace with remote urls

    let stage = getters.customizerStage;

    let files   = [];  // files to upload ~  {blob, name, id}

    // gift content
    let contentName;
    let contentFile;
    if(stage.contentInfo) {
      if(stage.contentInfo.file) {
        contentFile = heap.file(stage.contentInfo.file.url);
        contentName = stage.contentInfo.file.name;
        if(contentFile) {
          files.push( {file:contentFile.file, name:contentName, id:'content'} );
        }
      }
    }

    // custom wrap
    let wrapFile;
    if( stage.customizationMode === 'personal' ) {
      wrapFile = heap.file(stage.personal.wrap.src);
      if(wrapFile) {
        files.push({file:wrapFile.file, name:'wrap.jpg', id:'wrap'});
      }
    }

    // upload local urls to firebase storage
    return dispatch('uploadToBin', files)

    // update local urls to public urls
    .then((filesRes) => {
      console.log( filesRes );

      // map: id --> publicPath
      let publicPaths = {};
      for(let i in filesRes) {
        let file = filesRes[i];
        publicPaths[file.id] = file.publicPath;
      }

      // set stage values if in map...
      if('wrap' in publicPaths)     { stage.personal.wrap.src    = publicPaths['wrap'];    }
      if('content' in publicPaths ) { stage.contentInfo.file.url = publicPaths['content']; }

      return Promise.resolve();
    })

    // add stage to cart
    .then(() => {
      return dispatch('addCartItem', stage)
     .then(() => dispatch('clearCustomizerStage'))
     .then(() => Promise.resolve());
    });

  }
}
*/
