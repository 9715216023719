
const state = {
	tokens:{},  // {id:token, ...}
}

const mutations = {
	setCaptchaToken(state, {id, token}) {
		let tokensNew = {...state.tokens};
		tokensNew[id] = token;
		state.tokens = tokensNew;
	},
	consumeCaptchaToken(state, id) {
		let tokensNew = {...state.tokens};
		tokensNew[id] = null;
		state.tokens = tokensNew;
	},
}

const actions = {
}

const getters = {
	captchaToken: (state) => (id) => {
		return state.tokens[id];
	},
}

export default {
	state,
	mutations,
	actions,
	getters
}
