<template>

  <v-toolbar
    color="white"
    :dark="dark"
    :class="{'elevation-0':transparent, 'elevation-1':!transparent}"
    :style="[transparent ? {'background-color': 'transparent !important'} : {}]"

    :app="app"
    :fixed="fixed"
    :absolute="absolute"

    v-if="!hidden"
  >

    <!-- OLD Hamburger menu -->
    <!-- <v-toolbar-side-icon @click.stop="$emit('side-icon')" class="hidden-sm-and-up"></v-toolbar-side-icon> -->

    <!-- Logo -->
    <v-toolbar-title class="nav-base-title" style="padding:0;">
      <v-btn flat exact active-class="" to="/" class="pa-0" style="margin:0;">
        <!-- LOGO HERE -->
        <!-- TOM OLD: hidden-xs-only mr-2 -->
        <img class="nav-base-title-logo" src="/images/logo.svg" alt="Logo" style="height:32px; margin-right:7px;">
        <template v-if="h1">
          <h1 class="nav-base-title-name">{{title}}</h1>
        </template>
        <template v-else>
          <span class="nav-base-title-name">{{title}}</span>
        </template>
      </v-btn>
    </v-toolbar-title>

    <!-- Left-side items -->
    <v-toolbar-items class="hidden-sm-and-down">

      <v-btn v-for="(item, i) in items" :key="i" :to="item.route" :href="item.link" active-class="" flat exact>
        {{item.title}}
        <!-- <span>{{item.title}}</span> -->
        <!-- <v-icon>{{item.icon}}</v-icon> -->
      </v-btn>

    </v-toolbar-items>

    <v-spacer></v-spacer>

    <!-- Cart button -->
    <v-btn class="nav-base-cart-btn" to="/cart" active-class="" flat>
      <v-icon>shopping_cart</v-icon>
      <span v-if="$store.getters.cartTotal > 0.0000001" class="caption">
        ${{$store.getters.cartTotal}}
      </span>
    </v-btn>

    <!-- login/signup button  -->
    <v-btn v-if="!loggedIn" class="mr-1 hidden-xs-only" outline to="/login">
      Log in
    </v-btn>
    <v-btn v-if="!loggedIn" class="ml-1 px-1 hidden-xs-only" color="blue white--text" to="/signup">
      Sign up
    </v-btn>

    <!-- NEW Hamburger menu -->
    <v-toolbar-side-icon @click.stop="$emit('side-icon')" class="hidden-md-and-up"></v-toolbar-side-icon>

    <!-- TOM: Hidden on mobile -->
    <v-btn v-if="loggedIn" icon class="ml-2 hidden-xs-only" color="yellow darken-2 black--text" to="/gifts" active-class="">
      {{credits}}
    </v-btn>

    <v-menu offset-y v-if="loggedIn">

      <v-btn slot="activator" icon class="px-1" color="blue white--text">
        <v-icon>person</v-icon>
      </v-btn>

      <v-list class="pa-0">

        <v-list-tile to="/gifts" active-class="">
          <v-list-tile-title>Your Gifts</v-list-tile-title>
        </v-list-tile>

        <v-list-tile @click="logout">
          <v-list-tile-title>Log out</v-list-tile-title>
        </v-list-tile>

        <v-list-tile-content class="px-2 blue--text">
          {{email}}
        </v-list-tile-content>

      </v-list>

    </v-menu>


  </v-toolbar>

</template>

<script>
  export default {
    name:'nav-base',
    props: {
      title:       {type:String, default:'Swipewrap'},
      h1: {type:Boolean, default:false},

      dark:        {type:Boolean, default:false},
      transparent: {type:Boolean, default:false},

      app:         {type:Boolean, default:false},
      fixed:       {type:Boolean, default:false},
      absolute:    {type:Boolean, default:false},

      hidden:      {type:Boolean, default:false},

      items:       {type:Array, default:null}
    },
    computed: {

      loggedIn() {
        return this.$store.getters.user && this.$store.getters.user.emailVerified;
      },

      credits() {
        let res = 0;
        if(this.$store.getters.admin) { res = this.$store.getters.admin.credits; }
        return res;
      },

      email() {
        let res = null;
        if(this.$store.getters.user) { res = this.$store.getters.user.email }
        return res;
      },

    },
    methods: {

      logout() {
        this.$store.dispatch('clearAuth');
      },

    }
  }
</script>

<style scoped>

  .nav-base-title-name {
    font-family: Nunito, sans-serif !important;
    font-weight: 500;
    font-size: 23px;
  }

  a {
    text-decoration: none !important; /* remove underlines */
  }

  * {
    text-transform: none !important; /* prevent all caps */
  }

  /* logo tweak ~ unused ~ was going to show the logo icon in xs */
  @media only screen and (max-width: 370px) {
  }

  </style>

  <style>
  /* vuetify mods */

  .nav-base-cart-btn {
    margin: 0;
    padding: 0;
    min-width: 44px;
  }

  .nav-base-title {
    margin-left: inherit !important;
    margin-right: 10px;
  }
  .nav-base-title .v-btn {
    min-width: inherit;
  }

</style>
