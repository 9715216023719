<template>
  <!-- TOM: Appears to be a wrapper for NavBase for some reason... I think perhaps NavBase is used for both top and sticky navs... -->

  <!-- 'app' makes it optionally stick -->
  <nav-base
    :h1="h1"
    :dark="dark"
    :transparent="transparent"

    :fixed="fixed"
    :absolute="absolute"
    :app="app"

    v-if="!hidden"

    :items="items"

    @side-icon="$emit('side-icon')"
    @test="$emit('test')"
  >
  </nav-base>

</template>

<script>
  import NavBase from './NavBase.vue'
  export default {
    name:'nav-top',
    components: {
      NavBase
    },
    props: {
      title:       {type:String, default:'Swipewrap'},
      h1: {type:Boolean, default:false},

      dark:        {type:Boolean, default:false},
      transparent: {type:Boolean, default:false},

      fixed:       {type:Boolean, default:false},
      absolute: {type:Boolean, default:false},
      app: {type:Boolean, default:true},

      hidden:      {type:Boolean, default:false},

      items:       {type:Array, default:null}
    },
    methods: {}
  }
</script>
