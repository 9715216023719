/**
 * Performs deep clone of object o.
 */
export function clone(o) {
  if(!o) {return o;}
   let output, v, key;
   output = (o instanceof Array) ? [] : {};
   for (key in o) {
       v = o[key];
       output[key] = (typeof v === "object") ? clone(v) : v;
   }
   return output;
}

/**
 * All key/values from objA are copied into objB.
 */
export function cloneKeys(objA, objB) {  // a.keys --> b.keys
  let v, key;
  for (key in objA) {
      v = objA[key];
      if( typeof v === "object" && v != null ) {
        if( !(key in objB) || objB[key] == null ) objB[key] = {};
        cloneKeys(v, objB[key]);
      }else{
        objB[key] = v;
      }
  }
}

/**
 * Deprecated. Performed an undesirable shallow clone.
 */
// function cloneShallow(obj) {
//     if (null == obj || "object" != typeof obj) return obj;
//     var copy = obj.constructor();
//     for (var attr in obj) {
//         if (obj.hasOwnProperty(attr)) copy[attr] = obj[attr];
//     }
//     return copy;
// }

export default clone
