import debug from '@/util/debug'

import firebase from 'firebase/app'

import router from '@/router'

// ------------------------------------ //
// functions

// ------------------------------------ //

const state = {
  user:null,  // firebase user
  skips:{},   // actions to skip during a global module ping
}

const mutations = {
  setUser(state, user) {
    state.user = user;
  },
  addAuthSkip(state, action) {
    debug.log('adding auth skip: ' + action);
    state.skips[action] = true;
  },
  clearAuthSkips(state) {
    state.skips = {};
  }
}

const actions = {

  pingAuth({getters, commit, dispatch}) {
    if(getters.user) {
      debug.log('pingAuth: User already signed in. Taking no action.');
      return Promise.resolve();
    }else{
      debug.log('pingAuth: No user signed in. Signing in anonymously.');

      // skip authStateChange module updates on anonymous sign in
      commit('addAuthSkip', 'all');

      // first time sign in ~ skip module pings because nothing in db anyway
      return firebase.auth().signInAnonymously()
      .then(() => dispatch('pingModules', {modules:true}));

    }
  },

  // reload auth user
  reloadAuth({commit}) {
    if(getters.user) {
      debug.log('reloadAuth: reloading user.');
      return firebase.auth().currentUser.reload()
      .then(() => {

        return new Promise((resolve) => {
          commit('setUser', firebase.auth().currentUser);
          resolve();
        },400);

      })
    }else{
      debug.log('reloadAuth: No user. taking no action.');
    }
  },

  // forces user sign out
  clearAuth({getters, dispatch}) {
    if(getters.user) {
      debug.log('clearAuth: Signing out user.');
      return firebase.auth().signOut();
    }else{
      debug.log('clearAuth: No user. Clearing modules anyway.');
      dispatch('clearModules');
      return Promise.resolve();
    }
  },

  authStateChange({state, getters, commit, dispatch}, user) {
    let prev = getters.user;
    commit('setUser', user);
    if(user) {

      if(prev) {
        if(prev != user) {
          debug.log('Changing user. Handle transition externally.');
          debug.log(user.uid + ' ' + user.isAnonymous + ' ' + user.providerId);
        }else{
          debug.log('Same user. This should not happen.');
        }
      }else{
        debug.log('User signed in.');
        debug.log(user.uid + ' ' + user.isAnonymous + ' ' + user.providerId);

        // sign in actions ~ skips prevent certain modules from being pinged
        dispatch('pingModules', state.skips)
        .catch((error) => {
          console.log(error.message);
        });

        // clear skips
        if( Object.keys(state.skips).length > 0 ) commit('clearAuthSkips');
      }

      // checkout watcher
      dispatch('checkoutWatch', user);

    }else{

      if(prev) {
        debug.log('User signed out.');

        // go to logout page
        router.push('/logout');

        // sign out actions
        dispatch('clearModules');

        // set flag to false ~ just in case user signs out and signs back in with a new email.
        commit('setEmailVerificationSent', false);

      }else{
        debug.log('No user.');
      }

      // checkout watcher
      dispatch('checkoutClearWatch');

    }

  },

}

const getters = {
  user(state) { return state.user },

  // isnt reactive for some reason...
  userLoggedIn(state) {
    let res = false;
    if( state.user ) {
      if( state.user.emailVerified ) {
        res = true;
      }
    }
    return res;
  },
  
}

export default {
	state,
	mutations,
	actions,
	getters
}
