<template>

  <bottom-sheet v-model="active">

    <v-alert :type="type" :value="true" style="height:70px;" :dismissible="dismissible">
      {{message}} 
      <a :href="href">
        {{label}}
      </a>
    </v-alert>

  </bottom-sheet>

</template>

<script>
import BottomSheet from '@/components/misc/BottomSheet.vue'

export default {
  name:'bottom-bar',
  components: {
    BottomSheet
  },
  computed: {
    active: {
      get() { return this.$store.getters.bottomBarActive; },
      set(value) { this.$store.commit('setBottomBarActive', value); }
    },
    type() {
      return this.$store.getters.bottomBarType;
    },
    message() {
      return this.$store.getters.bottomBarMessage;
    },
    dismissible() {
      return this.$store.getters.bottomDismissable;
    },
    href() {
      return this.$store.getters.bottomHRef;
    },
    label() {
      return this.$store.getters.bottomLabel;
    },
  }
}
</script>
