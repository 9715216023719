// interfacess the 'user' object in the database and handles verification link.

import debug from '@/util/debug'

import firebase from 'firebase/app'
import forUser from '@/misc/firebase.for-user'

import router from '@/router'

const state = {
  user: null,
  emailVerificationSent: sessionStorage.getItem('emailVerificationSent') === 'true',
}

const mutations = {
  setAdmin(state, data) {
    state.user = data;
  },
  setEmailVerificationSent(state, value) {
    state.emailVerificationSent = value;
    sessionStorage.setItem('emailVerificationSent', value);
  },
}

const actions = {

  pingAdmin({getters, dispatch}) {
    if(!getters.admin) {
      return dispatch('retrieveAdmin');
    }
  },

  // retrieve db user data
  refreshAdmin({getters, commit}) {
    if(getters.user) {
      return Promise.resolve()
      .then(() => firebase.database().ref('/users/' + getters.user.uid).once('value')
      .then((snapshot) => {
        debug.log('refreshAdmin: retrieved user data');
        let userObj = snapshot.val();
        commit('setAdmin', userObj);
      }))
    }
  },

  retrieveAdmin({getters, commit}) {
    let db = firebase.database();
    if(getters.user) {
      let updates;

      debug.log('retrieveAdmin: pinging server with auth action');

      // promise chain...
      return Promise.resolve()

      // send verification email and go to verification page if not verified
      .then(() => {
        if(!getters.user.isAnonymous && !getters.user.emailVerified && !getters.emailVerificationSent) {
          debug.log('retrieveAdmin: sending verification email.');
          router.push('/verify-email');
          commit('setEmailVerificationSent', true);
          return getters.user.sendEmailVerification();
        }
      })

      // ping api/user on server
      .then(() => forUser(getters.user, 'post', '/api/user', {}))

      // log user db updates from server
      .then((response) => {
        updates = response.data;
        debug.log('retrieveAdmin: auth updates:');
        if(Object.keys(updates).length > 0) {
            for(let update in updates) { debug.log(update); }
        }else{
          debug.log('NO UPDATES');
        }
        return Promise.resolve();
      })

      // retrieve db user data
      .then(() => db.ref('/users/' + getters.user.uid).once('value').then((snapshot) => {
        debug.log('retrieveAdmin: retrieved user data');
        let userObj = snapshot.val();
        commit('setAdmin', userObj);
        return Promise.resolve();
      }))

    }else{
      console.log('retrieveAdmin: No user found. Cannot retrieve user data.');
      return Promise.resolve();
    }

  },

  clearAdmin({commit}) {
    commit('setAdmin', null);
  },

}

const getters = {
  admin(state) { return state.user },
  adminStripeCustomer(state) { return (state.user) ? state.user.stripeCustomer : null },
  emailVerificationSent(state) { return state.emailVerificationSent; },
}

export default {
	state,
	mutations,
	actions,
	getters
}
