import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/Home.vue';

import blogs from '@/views/blogs';

Vue.use(Router);

// NOTE: see router.beforeEach for meta tag creation on pages

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  // fix ~ the smooth scrolling polyfill seemed to mess up page scroll when changing pages ~ this causes the page to scroll to the top upon route changes
  // src: https://github.com/vuejs/vue-router/issues/3451#issuecomment-874483213
  scrollBehavior(to, from, savedPosition) {
    if(savedPosition) {
      return savedPosition;
    }
    if(to.hash) {
      return { el: to.hash, behavior: 'smooth' };
    }else{
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'instant',
      });
    }
  },
  routes: [

    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {
        title: 'Swipewrap',
        meta: [
          { name: 'description', content: 'Digital gift wrap to digitally wrap and send your gifts in a way that is fun, personal, and convenient while your gifted items arrive separately. Digitally wrap anything in a new era of gifting!' },
        ],
      },
    },

    // route level code splitting ~ lazy-loaded chunk (page.[hash].js) when using import
    {
      path: '/demo',
      name: 'demo',
      component: () => import('./views/Demo.vue'),
      meta: {
        title: 'Swipewrap Demo',
      },
    },
    // REMOVE ~~~ or ~~~ make an about page later...
    // {
    //   path: '/about',
    //   name: 'about',
    //   component: () => import('./views/About.vue')
    // },
    {
      path: '/blog',
      name: 'blog',
      component: () => import('./views/Blog.vue'),
      meta: {
        title: 'Swipewrap Blog · The World of Digital Gifting',
      },
    },
    ...blogs,
    {
      path: '/gift-content-type',
      name: 'gift-content-type',
      component: () => import('./views/GiftContentType.vue'),
      meta: {
        title: 'Send Gift',
      },
    },
    {
      path: '/customizer',
      name: 'customizer',
      component: () => import('./views/Customizer.vue'),
      meta: {
        title: 'Customizer',
      },
    },
    {
      path: '/cart',
      name: 'cart',
      component: () => import('./views/Cart.vue'),
      meta: {
        title: 'Your Cart',
      },
    },
    {
      path: '/checkout-success',
      name: 'checkout-success',
      component: () => import('./views/CheckoutSuccess.vue'),
      meta: {
        title: 'Checkout Successful, Please Wait...',
      },
    },
    {
      path: '/checkout-done',
      name: 'checkout-done',
      component: () => import('./views/CheckoutDone.vue'),
      meta: {
        title: 'Checkout Complete!',
      },
    },
    {
      path: '/gifts',
      name: 'gifts',
      component: () => import('./views/Gifts.vue'),
      meta: {
        title: 'Your Gifts',
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('./views/Login.vue'),
      meta: {
        title: 'Sign in',
      },
    },
    {
      path: '/signup',
      name: 'signup',
      component: () => import('./views/Signup.vue'),
      meta: {
        title: 'Sign up',
      },
    },
    {
      path: '/verify-email',
      name: 'verify-email',
      component: () => import('./views/VerifyEmail.vue'),
      meta: {
        title: 'Verify Email',
      },
    },
    {
      path: '/logout',
      name: 'logout',
      component: () => import('./views/Logout.vue'),
      meta: {
        title: 'Logged Out',
      },
    },
    {
      path: '/privacy-policy',
      name: 'privacy-policy',
      component: () => import('./views/PrivacyPolicy.vue'),
      meta: {
        title: 'Privacy Policy',
      },
    },
    {
      path: '/tos',
      name: 'tos',
      component: () => import('./views/TermsOfService.vue'),
      meta: {
        title: 'Terms of Service',
      },
    },
    {
      path: '/logo',
      name: 'logo',
      component: () => import('./views/Logo.vue'),
      meta: {
        title: 'Logo',
      },
    },

  ]
});

// change title and meta tags
// src: https://www.digitalocean.com/community/tutorials/vuejs-vue-router-modify-head#:~:text=How%20To%20Update%20Page%20Title%20and%20Metadata%20with,Route%20Meta%20Fields%20and%20a%20Navigation%20Guard.%20
router.beforeEach((to, from, next) => {
  // NOTE: to.meta always exists

  // set title
  document.title = to.meta.title || 'Swipewrap Page';

  // remove previous vue-router-controlled elements
  document.querySelectorAll('[data-vue-router-controlled]').forEach((el) => { el.parentNode.removeChild(el); });

  // add meta tags
  // NOTE: just in case, I'm not using "for...of" statements becase there aren't any anywhere else... <-- this is probably silly, but whatever
  if(to.meta.meta) {
    for(let i = 0; i < to.meta.meta.length; i++) {
      const data = to.meta.meta[i];
      const el = document.createElement('meta');
      // set attributes
      for(let key in data) { el.setAttribute(key, data[key]); }
      // set vue-router-controlled so element can be destroyed later
      el.setAttribute('data-vue-router-controlled', '');
      // append
      document.head.appendChild(el);
    }
  }

  next();
});

export default router;
