 <template>

  <div
    class="bottom-sheet"
    :style="{
      'bottom': offsetBottom+'px',
      'height': (value) ? height+'px' : 0,
      'z-index': zIndex,
      'background-color': color,
    }"
  >

    <div ref="content">
      <slot></slot>
    </div>

  </div>

</template>

<script>
export default {
  name:'bottom-sheet',
  components: {
  },
  props: {
    // colorClassContainer:{type:String, default:'grey lighten-4'},
    // colorClassContent:{type:String, default:'white'},

    fullScreen:{type:Boolean, default:false},
    offsetTop:{type:Number, default:28},      // offset from top in pixels
    offsetBottom:{type:Number, default:0},    // offset from bottom in pixels

    top:{type:Boolean, default:true},         // if needs to be on top (z-index)

    value: {type:Boolean, default:false},
  },
  data() {
    return {
      height:0,
      color:null,
    }
  },
  computed: {
    zIndex() {
      return (this.top)
        ? 450
        : (this.value) ? 250 : 240;
    },
  },
  watch: {
    value() {
      //if(this.value) console.log( this.height + this.offsetBottom );
      //this.$emit('');
    }
  },
  mounted() {this.updateElement();},
  updated() {this.updateElement();},
  methods: {

    updateElement() {

      this.$nextTick(() => {
        
        // height transitions
        let maxHeight = window.innerHeight - this.offsetTop - this.offsetBottom;
        let contentHeight = this.$refs.content.offsetHeight;
        if(this.fullScreen || contentHeight >= maxHeight) { contentHeight = maxHeight; }
        this.height = contentHeight;

        // emit change in height
        if(this.value)
          this.$emit( 'update', this.height + this.offsetBottom );

      });

    }
  }
}

</script>

<style scoped>

.bottom-sheet {
  position: fixed;
  left: 0;
  right: 0;
  overflow: hidden;

  -webkit-transition: height 0.3s cubic-bezier(.25,.8,.50,1);
  -moz-transition: height 0.3s cubic-bezier(.25,.8,.50,1);
  -o-transition: height 0.3s cubic-bezier(.25,.8,.50,1);
  transition: height 0.3s cubic-bezier(.25,.8,.50,1);
}

/* transition */
.bottom-sheet-layout-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

</style>
