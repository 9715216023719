import axios from 'axios'

export function forUser(user, action, route, data) {
  if(!data) data = null;
  return user.getIdToken(true).then((token) => {
    return axios[action](route, {
      data:data,
      token:token
    });
  });
}

export default forUser
