import Vue from 'vue'
import Vuex from 'vuex'

import auth from './modules/auth'
import admin from './modules/admin'
import bin from './modules/bin'
import cart from './modules/cart'
import gifts from './modules/gifts'
import customizer from './modules/customizer'
import checkout from './modules/checkout'
import stripe from './modules/stripe'
import firstGiftToken from './modules/first-gift-token'
import captcha from './modules/captcha'
import tangoCard from './modules/tango-card'
import bottomBar from './modules/bottom-bar'
import test from './modules/test'

import * as actions from './actions'

Vue.use(Vuex);

export default new Vuex.Store({
  actions,
  modules: {
    auth,
    admin,
    bin,
    cart,
    gifts,
    customizer,
    checkout,
    stripe,
    firstGiftToken,
    captcha,
    tangoCard,
    bottomBar,
    test
  }
});
