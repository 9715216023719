// Interface for session storage for files. Calling it 'heap'.
//
// Acts like a disk storage + staging area

import debug from '@/util/debug'

// write file: add dataURL to sessionStorage
// read file: get dataURL, parse blob from data, create objectURL, return
// 'caches' blob object urls in 'blobs' object during session
// NOTE: 'blob' in this file mostly refers to blob object urls.

// ----------------------------------------------- //
// config

// HERE - heap max size ~~~ 3.8 MB
// NOTE: typical image file size is 6 MB

// allowed space in sessionStorage ~ in bytes
let MAX_SIZE = 3800000;  // 3.8 MB

const DATA_PREFIX = '__HEAP_DATA__';
const IS_PREFIX   = '__HEAP_IS__';
const SIZE_PREFIX = '__HEAP_SIZE__';
const SIZE_KEY    = '__HEAP_SIZE__';
const TREE_KEY    = '__HEAP_TREE__';

let stage = {};  // file obj staging  ~ {heapPath : file}
let blobs = {};  // blobUrl cache     ~ {heapPath : blobUrl}
let files = {};  // file obj cache    ~ {blobUrl : {name, file}}
let tree = {};   // directories       ~ { dir: { dir: { name:true } } } ~ true = saved. false = staged.

let size = 0;    // total occupied space in sessionStorage

// ----------------------------------------------- //
// init

// get size
size = Number(sessionStorage.getItem(SIZE_KEY));
if( !size ) size = 0;

// get tree
tree = JSON.parse(sessionStorage.getItem(TREE_KEY));
if( !tree ) tree = {};

// ----------------------------------------------- //
// utils

function dataURItoBlob(dataURI) {

  // convert base64/URLEncoded data component to raw binary data held in a string
  var byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0)
    byteString = atob(dataURI.split(',')[1]);
  else
    byteString = unescape(dataURI.split(',')[1]);

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length);
  for(var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
}

function sanitizeDir(dir) {
  if(!dir || dir === '') { dir = '/'; }
  if( dir[0] !== '/' ) { dir = '/' + dir; }
  if( dir.length > 1 && dir[dir.length-1] !== '/' ) { dir = dir + '/'; }
  return dir;
}

function updateTree(tree, path, saved) {
  let pathArray = path.substring(1).split('/');
  let o = tree;
  for( let i = 0; i < pathArray.length-1; i++ ) {
    let dir = pathArray[i];
    if(!(dir in o)) o[dir] = {};
    o = o[dir];
  }
  o[ pathArray[ pathArray.length-1 ] ] = saved;
}

function getTrueTree(tree) {
  let o = {};
  _getTrueTree(o, tree);
  return o;
}
function _getTrueTree(o, obj) {
  let i;
  for(i in obj) {

    if(typeof obj[i] === 'object') {
      // recurs
      o[i]={};
      _getTrueTree(o[i], obj[i]);
      // if nothing there, delete on the way back up...
      if( Object.keys(o[i]).length == 0 ) { delete o[i]; }
    }else{
      // add trues
      if( obj[i] == true ) { o[i] = true; }
    }

  }
}

// ----------------------------------------------- //
// debug

const debugModule = false;

function debugLogStats() {
  if(debugModule) {

    debug.log('heap cache:');
    debug.log(blobs);

    debug.log('heap files:');
    debug.log(files);

    debug.log('heap stage:');
    debug.log(stage);

    debug.log('heap tree:');
    debug.log(JSON.stringify(tree,null,2));

    debug.log('session storage:');
    let keys = Object.keys(sessionStorage);
    for(let i in keys)
      debug.log(keys[i]);
    debug.log('heap size:');
    debug.log(size);

  }
}
debugLogStats();

// ----------------------------------------------- //
// notes

// ----------------------------------------------- //
// stage operations

function put(file, name, dir) {
  debug.log('heap: puting on stage');
  dir = sanitizeDir(dir);
  let path = dir + name;

  // create an object URL for it if not already there
  let blobUrl = URL.createObjectURL(file);
  stage[path] = file;                          // add to stage
  blobs[path] = blobUrl;                       // cache blobUrl
  files[blobUrl] = { name: name, file:file };  // cache file obj

  // update tree (staged files have a false flag)
  updateTree(tree, path, false);

  // debug
  debugLogStats();

  return blobUrl;
}

function putData(data, name, dir) {
  let blob;

  // create file from the data
  // note: file is just a blob with extra info. okay to add keys.
  blob = dataURItoBlob(data);  // create blob
  blob.name = name;            // add extra data

  // call put
  return put(blob, name, dir);
}

// ----------------------------------------------- //
// main storage operations

function write(file, name, dir) {
	debug.log('heap: writing...');
  dir = sanitizeDir(dir);
  let path = dir + name;

  // stage checking
  let fromStage = false;
  if(!file && name) {
    // try to get file from stage if in stage if no file param passed
    file = stage[path];
    delete stage[path];
    fromStage = true;
  }else{
    // otherwise, check if the same file is on the stage
    if(stage[path]) {
      if( stage[path].size == file.size &&
          stage[path].name === file.name &&
          stage[path].type === file.type ) {
        debug.log('heap: file name found in stage. clearing stage item.');
        // cache for blob url and file already exsit for same file, just clear stage
        file = stage[path];
        delete stage[path];
        fromStage = true;
      }
    }
  }

	return new Promise((resolve, reject) => {
		let reader = new FileReader();
		reader.onload = (e) => {
			let data = e.target.result;  // the dataURL

      // if already there, get prev size
      let prevFileSize = (stored(path)) ? Number(sessionStorage.getItem(SIZE_PREFIX + path)) : 0;
      let newFileSize = data.length;

      // reject if too big
      if(size - prevFileSize + newFileSize > MAX_SIZE) {
        reject(new Error('heap full'));
        return;
      }

      // update heap size
      size -= prevFileSize;
      size += newFileSize;
      sessionStorage.setItem(SIZE_KEY, size);

      // update tree
      updateTree(tree, path, true);
      sessionStorage.setItem(TREE_KEY, JSON.stringify(getTrueTree(tree)));

			// save to session storage
			sessionStorage.setItem(DATA_PREFIX + path, data);
			sessionStorage.setItem(IS_PREFIX + path, true);
      sessionStorage.setItem(SIZE_PREFIX + path, newFileSize);

			// create an object URL for it if not already coming from stage
      let blobUrl;
      if( fromStage ) {
        blobUrl = blobs[path];
      }else{
        debug.log('heap: creating blob url.');
        blobUrl = URL.createObjectURL(file);
        blobs[path] = blobUrl;                       // cache blobUrl
        files[blobUrl] = { name: name, file:file };  // cache file obj
      }

			// debug
			debugLogStats();

			resolve(blobUrl);
		};
		reader.readAsDataURL(file);
	});

}

function writeData(data, name, dir) {
  let blob;

  // create file from the data
  // note: file is just a blob with extra info. okay to add keys.
  blob = dataURItoBlob(data);  // create blob
  blob.name = name;            // add extra data

  // call write
  return write(blob, name, dir);
}

function read(nameOrPath, dir) {
	debug.log('heap: reading...');
  let path;
  if(dir) { dir = sanitizeDir(dir); path = dir + nameOrPath; } else { path = nameOrPath; }

	// already in the blob map, return.
	let blobUrl = blobs[path];
	if( blobUrl ) {
		debug.log('heap: found in existing blobs');
    debugLogStats();
		return blobUrl;
	}

	// check sessionStorage and blob cache.
	if(is(path)) {
		debug.log('heap: found in sessionStorage');
		let data = sessionStorage.getItem(DATA_PREFIX + path);  // get data
    let blob = dataURItoBlob(data);                         // create blob
		blobUrl = URL.createObjectURL(blob);                    // create blob url
    blobs[path] = blobUrl;                       // cache blobUrl
    files[blobUrl] = { name: name, file:blob };  // cache file obj
    debugLogStats();
		return blobUrl;
	}

	// not there...
	debug.log('heap: not found');
	return null;

}

function clear(nameOrPath, dir) {
  let path;
  if(dir) { dir = sanitizeDir(dir); path = dir + nameOrPath; } else{ path = nameOrPath; }

  // cehck if stored
  if(!stored(path)) {
    debug.log('heap: (clear) ' + path + ' not found');
    return;
  }

  // get prev size
  let fileSize = Number(sessionStorage.getItem(SIZE_PREFIX + path));

  // update heap size
  size -= fileSize;
  sessionStorage.setItem(SIZE_KEY, size);

  // update tree
  updateTree(tree, path, false);
  sessionStorage.setItem(TREE_KEY, JSON.stringify(getTrueTree(tree)));

  // clear from session storage
  sessionStorage.removeItem(DATA_PREFIX + path);
  sessionStorage.removeItem(IS_PREFIX + path);
  sessionStorage.removeItem(SIZE_PREFIX + path);

}

// ----------------------------------------------- //
// checks and the module ping

// returns if in session storage
function stored(nameOrPath, dir) {
  let path;
  if(dir) { dir = sanitizeDir(dir); path = dir + nameOrPath;}
  else{ path = nameOrPath; }
  return ( sessionStorage.getItem(IS_PREFIX + path) ) ? true : false;
}

// returns if in blobs cache or in session storage
function is(nameOrPath, dir) {
  let path;
  if(dir) { dir = sanitizeDir(dir); path = dir + nameOrPath; } else { path = nameOrPath; }
  return ( blobs[path] || sessionStorage.getItem(IS_PREFIX + path) ) ? true : false;
}

// if stored locally (cache or session storage), return a blob url. otherwise, return param url.
function ping(url, nameOrPath, dir) {
  let path;
  if(dir) { dir = sanitizeDir(dir); path = dir + nameOrPath; } else { path = nameOrPath; }
  return ( is(path) ) ? read(path) : url ;
}

// ----------------------------------------------- //
// file getter interface

function file(url) {
  return files[url];
}

// ----------------------------------------------- //
// export

export default {
  write,
  writeData,
  read,
  clear,
  is,
  stored,
  put,
  putData,
  ping,
  file,
}
