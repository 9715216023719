const debugLog = (process.env.VUE_APP_DEBUG_LOG === 'true');

if(debugLog) {
  console.log('DEBUG LOGGING ON');
}

export default {

  log:(value) => {
    if(debugLog) {
      console.log(value);
    }
  }

}
