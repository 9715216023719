<template>
  <div class="nav-scroll" v-scroll="onScroll">

    <transition name="fade-transition" mode="out-in">
      <nav-base
        :dark="dark"
        :transparent="transparent"

        :fixed="fixed"
        :absolute="!fixed"

        v-if="!hidden"

        :items="items"

        @side-icon="$emit('side-icon')"
        @test="$emit('test')"
      >
      </nav-base>
    </transition>

  </div>
</template>

<script>
import NavBase from './NavBase.vue'
export default {
  name:'nav-scroll',
  components: {
    NavBase
  },
  props: {
    title:       {type:String, default:'Swipewrap'},

    dark:        {type:Boolean, default:false},
    transparent: {type:Boolean, default:false},

    fixed:       {type:Boolean, default:true},

    items:       {type:Array, default:null},

    passElement:null
  },
  data() {
    return {
      hidden:true
    }
  },
  methods: {

    onScroll() {

      // show/hide
      if( this.passElement ) {
        if( this.passElement.$el ) {
          this.hidden = this.passElement.$el.getBoundingClientRect().bottom > 0;
        }else{
          this.hidden = this.passElement.getBoundingClientRect().bottom > 0;
        }
      }
    }

  }

}
</script>
