// user object storage interface
import debug from '@/util/debug'

import firebase from '@/libs/firebase'
import forUser from '@/misc/firebase.for-user'

// ------------------------------------ //
// functions

//
function finalizeUserBinUploads(user, bin, uploads) {

	let promises = [];
	for(let i in uploads) {
		promises.push(Promise.resolve().then(() => {
			let index = i;
			let file = uploads[index];

			// get download url
			return firebase.storage().ref().child('/' + bin + '/' + '__' + file.binKey + '__' + file.name).getDownloadURL()

			// update db
			.then((downloadURL) => {
				file.publicPath = downloadURL;
				return forUser(user, 'post', '/api/bin/finalize', {
					bin:bin,
					key:file.binKey,
					name:file.name,
					publicPath:file.publicPath
				});
			});

		}));
	}
	return Promise.all(promises);

}

// ------------------------------------ //

const state = {
}

const mutations = {
}

const actions = {

	// finalize bin entry
	finalizeBinUploads({getters, dispatch}, {bin, uploads}) {
		debug.log( 'bin: finalizing bin item' );
		if(getters.user) {
			return finalizeUserBinUploads(getters.user, bin, uploads);
		}else{
			return dispatch('pingAuth').then(() => {
				return finalizeUserBinUploads(getters.user, bin, uploads);
			});
		}
	},

}

const getters = {
}

export default {
	state,
	mutations,
	actions,
	getters
}

// ------------------------------------ //
// old upload methods

// unused
/*
uploadToBin({getters, dispatch}, files) {
	debug.log( 'bin: uploading files' );
	if(getters.user) {
		return addFilesToUserBin(getters.user, getters.admin.bin, files);
	}else{
		return dispatch('pingAuth').then(() => {
			return addFilesToUserBin(getters.user, getters.admin.bin, files);
		});
	}
},
*/

// unused
/*
function addFilesToUserBin(user, bin, files) {

	let filesURL = [];
	let filesRes;

	// create dataURLs for each file in files. save into filesURL.
	let promises = [];
	for(let i in files) {
		promises.push(Promise.resolve().then(() => {
			let index = i;
			return fileToDataURL(files[index].file).then((dataURL) => {
				filesURL[index] = {};
				filesURL[index].file = dataURL;
				filesURL[index].name = files[index].name;
				filesURL[index].id = files[index].id;
				return Promise.resolve();
			});
		}));
	}

	// wait for dataURLs
	return Promise.all(promises)

	// upload files to firebase storage
	.then(() => forUser(user, 'post', 'api/bin/upload', filesURL))

	// get public urls and update db bin
	.then((response) => {
		filesRes = response.data;

		let promises = [];
		for(let i in filesRes) {
			promises.push(Promise.resolve().then(() => {
				let index = i;
				let file = filesRes[index];

				// get download url
				return firebase.storage().ref().child('/' + bin + '/' + '__' + file.binKey + '__' + file.name).getDownloadURL()

				// update db
				.then((downloadURL) => {
					file.publicPath = downloadURL;
					return forUser(user, 'post', '/api/bin/set', {
						bin:bin,
						key:file.binKey,
						name:file.name,
						publicPath:file.publicPath
					});
				});

			}));
		}
		return Promise.all(promises);

	})

	// return file infos for each file
	.then(() => {
		debug.log( 'bin: DONE' );
		return Promise.resolve(filesRes);
	});

}
*/

// form data object
/*
let formData = new FormData();
formData.append('file', file);
formData.append('name', name);
return axios.post('api/bin/test_file_size', formData);
*/

// single file
/*
let binKey;
let publicPath;
return forUser(user, 'post', '/api/bin/push', {bin})

// get key and upload to storage
.then((response) => {
	debug.log( 'bin: received push key ' + response.data );
	binKey = response.data;
	return firebase.storage().ref().child('/' + bin + '/' + '__' + binKey + '__' + name).put(file);
})

// update db with storage results
.then((snapshot) => {
	debug.log( 'bin: ' + name + ' uploaded' );
	return snapshot.ref.getDownloadURL()
	.then((downloadURL) => {
		publicPath = downloadURL;
		return forUser(user, 'post', '/api/bin/set', {
			bin:bin,
			key:binKey,
			name:name,
			publicPath:publicPath
		});
		// NOTE: decided not to store localPath on db since it can be determined.
		// localPath = '/bin/__binKey__name'
	});
})

// return file info
.then(() => {
	debug.log( 'bin: DONE' );
	return Promise.resolve({name, key:binKey, publicPath});
});
*/
