
// get if nested value/reference exists in an object from a string
export function inFromString(obj, key) {

	// split into array
	let keys = key.split('.');

	// loop to nested reference
	let o = obj;
	for(let i=0; i < keys.length-1; i++) {
		if(keys[i] in o) { o=o[keys[i]]; }
		else{ return false; }
	}

	// return
	return (keys[keys.length-1] in o);

}

// get or set a nested value/reference in an object from a string
export function fromString(obj, key, to) {

	// split into array
	let keys = key.split('.');

	// loop to nested reference
	let o = obj;
	for(let i=0; i < keys.length-1; i++) {
		if(o) {
			if(keys[i] in o) { o=o[keys[i]]; }
			else{ return; }  // return undefined
		}else{
			return o;        // return. o ~ undefined or null.
		}
	}

	// set value and return it
	if(o) {
		if(keys[keys.length-1] in o) {
			if(typeof to != 'undefined') { o[keys[keys.length-1]] = to; }
			return o[keys[keys.length-1]];
		}else{
			return;  // return undefined
		}
	}else{
		return;
	}

}

export default fromString
