/* global Stripe */

// Stripe client
export const stripe = Stripe(process.env.VUE_APP_STRIPE_API_KEY_PUBLIC, { betas: ['payment_intent_beta_3'] });

// Stripe Elements
export const elements = stripe.elements();

// default export
export default stripe

// ----------------------------------------------------- //
// old

/*
// index.html
<!-- <script src="https://js.stripe.com/v3/"></script> -->

// store import
import {stripe} from '@/libs/stripe'

// old vuex store action
doStripeUserCharge({getters}, card) {
  let stripeToken;

  if(!getters.user) {
    debug.log( 'doStripeUserCharge: on user available for cart' );
    return Promise.reject(new Error('no user'));
  }

  return stripe.createToken(card)
  .then((result) => {

    if(result.error) {
      debug.log( 'doStripeUserCharge: error - ' + result.error.message );
      return Promise.reject(result.error);
    }else{
      stripeToken = result.token.id;
      debug.log( 'doStripeUserCharge: token - ' + stripeToken );
      return getters.user.getIdToken(true);
    }

  })
  .then((token) => {
    if(getters.adminStripeCustomer) {
      // charge user
      return axios.post('/api/charge', {
        stripeToken:stripeToken,
        stripeCustomer:getters.adminStripeCustomer,
        token:token
      });
    }else{
      // server creates stripe customer and saves it to db user
      return axios.post('/api/charge', {stripeToken:stripeToken, token:token});
    }

  });

}
*/
