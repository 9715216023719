import debug from '@/util/debug'

//import firebase from 'firebase/app'

import forUser from '@/misc/firebase.for-user'

const state = {
  sent:undefined,
  received:undefined
}

const mutations = {

  setGiftsSent(state, gifts) { state.sent = gifts; },

  setGiftsReceived(state, gifts) { state.received = gifts; },

}

const actions = {

  pingGifts({getters, dispatch}) {
    if( !getters.giftsSent && !getters.giftsReceived ) {
      return dispatch('retrieveGifts');
    }
  },

  retrieveGifts({getters, commit}) {
    if(getters.user) {

      if(getters.admin) {
        if( getters.admin.gifts ) {
          let promises = [];
          let sent = {};
          let received = {};

          // sent gifts
          if(getters.admin.gifts.sent) {
            promises.push(
              forUser(getters.user, 'post', '/api/gifts', getters.admin.gifts.sent)
              .then((response) => {
                sent = response.data;
              })
            );
          }

          // received gifts
          if(getters.admin.gifts.received) {
            promises.push(
              forUser(getters.user, 'post', '/api/gifts', getters.admin.gifts.received)
              .then((response) => {
                received = response.data;
              })
            );
          }

          return Promise.all(promises).then(() => {
            debug.log('retrieveGifts: retreived gifts.');
            debug.log( sent );
            debug.log( received );
            commit('setGiftsSent', sent);
            commit('setGiftsReceived', received);
            return Promise.resolve();
          });

        }
      }

    }else{
      console.log('retrieveGifts: No user found. Cannot retreive gifts.');
      return Promise.resolve(null);
    }
  },

  setGiftAsCustomizerStageIntent({dispatch}, {key, gift}) {
    dispatch('setCustomizerStageIntent', gift);
    dispatch('setCustomizerStageCallback', {
      func: function(store, data) {
        console.log( data.key );
      },
      data:{key}
    });
  },

  // updateAGift(updatedObj) ~ note server will have to select specific changes to prevent 'cheating'
    // send update to server
    // on update, retreive gifts

  clearGifts({commit}) {
    commit('setGiftsSent', null);
    commit('setGiftsReceived', null);
  },

}

const getters = {

  giftsSent(state) { return state.sent },

  giftsReceived(state) { return state.received },

}

export default {
	state,
	mutations,
	actions,
	getters
}
