import Vue from 'vue'
import {
  Vuetify,
  VApp,
  VNavigationDrawer,
  VFooter,
  VList,
  VBtn,
  VIcon,
  VGrid,
  VToolbar,
  VCard,
  VImg,
  VAlert,
  VBottomSheet,
  VProgressLinear,
  VTabs,
  VBottomNav,
  VMenu,
  VDialog,
  VSlider,
  VProgressCircular,
  VTextField,
  VTextarea,
  VSwitch,
  VRadioGroup,
  VDatePicker,
  VTimePicker,
  VSelect,
  VCarousel,
  VDataTable,
  transitions,
} from 'vuetify'
import 'vuetify/src/stylus/app.styl'
import * as directives from 'vuetify/es5/directives'

Vue.use(Vuetify, {
  components: {
    VApp,
    VNavigationDrawer,
    VFooter,
    VList,
    VBtn,
    VIcon,
    VGrid,
    VToolbar,
    VCard,
    VImg,
    VAlert,
    VBottomSheet,
    VProgressLinear,
    VTabs,
    VBottomNav,
    VMenu,
    VDialog,
    VSlider,
    VProgressCircular,
    VTextField,
    VTextarea,
    VSwitch,
    VRadioGroup,
    VDatePicker,
    VTimePicker,
    VSelect,
    VCarousel,
    VDataTable,
    transitions
  },
  directives,
  theme: {
    primary: '#2196F3',
    secondary: '#9e9e9e',
    accent: '#82B1FF',
    error: '#FF5252',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FFC107'
  },
  customProperties: true,
  iconfont: 'md',
})
