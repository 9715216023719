<template>
  <div ref="firebaseuiContainer">
  </div>
</template>

<script>
import firebaseui from '@/libs/firebaseui'
export default {
  name: 'firebase-u-i',
  props: {
    callback:null,
  },
  mounted() {
    firebaseui.start(this.$refs.firebaseuiContainer, this.callback);
  },
}
</script>
