<template>
  <!-- Used in the home page at least... -->

  <div class="main-nav">

      <!-- Top Nav (app makes it optionally stick)  -->
      <nav-top
        :h1="h1"
        :dark="dark"
        :transparent="transparent"

        :fixed="fixed"
        :absolute="absolute"
        :app="app"

        :items="items"

        @test="test"
        @side-icon="drawer=!drawer"
      >
      </nav-top>

      <!-- Secondary Nav ~ can show/hide -->
      <nav-scroll
        :dark="false"
        :passElement="passElement"

        :items="items"

        @test="test"
        @side-icon="drawer=!drawer"
      >
      </nav-scroll>

      <!-- Drawer Nav -->
      <nav-drawer
        v-model="drawer"
      >
      </nav-drawer>

  </div>
</template>

<script>
  import mainMenu from '@/static/main-menu'

  import NavTop from './NavTop.vue'
  import NavScroll from './NavScroll.vue'
  import NavDrawer from './NavDrawer.vue'

  export default {
    name:'main-nav',
    components: {
      NavTop,
      NavScroll,
      NavDrawer
    },
    props: {
      h1: {type:Boolean, default:false},
      passElement:null,
      dark:{type:Boolean, default:false},
      fixed:{type:Boolean, default:false},
      absolute:{type:Boolean, default:false},
      app:{type:Boolean, default:true},
      transparent:{type:Boolean, default: false},
      items:{type:Array, default: () => mainMenu.items},
    },
    data () {
      return {
        drawer: false,
        title: 'Swipewrap'
      }
    },
    computed: {
    },
    methods: {

      test() {

        if( this.passElement ) {
          if( this.passElement.$el ) {
            console.log( this.passElement.$el );
          }
        }

      }

    }
  }
</script>
