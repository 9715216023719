// indicates whether tango cards are sold out

import debug from '@/util/debug'
import axios from 'axios'
import forUser from '@/misc/firebase.for-user'

const state = {
	available:true,
}

const mutations = {
	setTangoCardAvailable(state, value) { state.available = value; },
}

const actions = {

	// called on page load ~ see's if tango cards are sold out or available
	pingTangoCard({commit, dispatch}) {
		return axios.get('/api/tango-card')
		.then((response) => {
			let available = response.data;
			debug.log('tango-card: available ' + available);
			commit('setTangoCardAvailable', available);

			// alert when not available
			if(!available) {
				setTimeout(() => {
					dispatch('showMessage', {type:'error', message:'We are currently sold out of Tango Cards. Please check in later for Tango Cards.'});
				}, 2000);
			}
			
		});
	},

	// called right before checkout ~ prevents stripe from popping up
	checkTangoCardForCart({getters}) {
		return forUser(getters.user, 'post', '/api/tango-card')
		.then((response) => Promise.resolve(response.data));
	},

}

const getters = {
	tangoCardAvailable(state) { return state.available; },
}

export default {
	state,
	mutations,
	actions,
	getters
}
